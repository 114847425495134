import React, { useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as CrossIcon } from '../../assets/images/cross.svg';

const infoBannerLevels = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  SUCCESS: 'success',
};

const InfoBanner = ({ Icon, title, level = infoBannerLevels.INFO, children }) => {
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  const classes = clsx(
    {
      'info-banner--info': level === 'info',
      'info-banner--warn': level === 'warn',
      'info-banner--error': level === 'error',
      'info-banner--success': level === 'success',
    },
    'info-banner',
  );

  const handleOnClick = () => {
    setIsBannerClosed(true);
  };

  return (
    !isBannerClosed && (
      <div className={classes}>
        {Icon && (
          <div className="info-banner__icon">
            <Icon />
          </div>
        )}
        <div className="info-banner__text">
          <p className="info-banner__text__title">{title}</p>
          {children}
        </div>
        <div className="info-banner__close">
          <button type="button" className="info-banner__close-btn" onClick={handleOnClick}>
            <CrossIcon />
          </button>
        </div>
      </div>
    )
  );
};

export { InfoBanner as default, infoBannerLevels };
