import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../assets/images/back.svg';

const BackButton = ({ toRoute }) => (
  <div className="back-button">
    <Link arial-label="Back" to={toRoute}>
      <BackIcon title="back" />
    </Link>
  </div>
);

export default BackButton;
