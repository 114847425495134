import React from 'react';

import Button from '../Button';

const FixedButton = ({ children, ...extraProps }) => (
  <div className="fixed-btn">
    <Button fullWidth {...extraProps}>
      {children}
    </Button>
  </div>
);

export default FixedButton;
