import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';

const MenuModalModifierCheckbox = ({ modifier, modifierIndex, handleSetModifiersTotal }) => {
  const { register } = useFormContext();
  const { label, modifierId, modifierItems, maxSelections } = modifier;
  const [selected, setSelected] = useState([]);
  const modifierHint = `No more than ${maxSelections}`;

  const handleOnChange = (itemId) => {
    setSelected((prevState) => {
      const newState = [...prevState];
      const newItem = { itemId };
      const itemFound = newState.find((item) => item.itemId === itemId);

      if (itemFound) {
        return newState.filter((item) => item.itemId !== itemId);
      }

      return [...newState, newItem];
    });
  };

  const getCheckedItemStatus = useCallback(
    (itemId) => selected.find((item) => item.itemId === itemId),
    [selected],
  );

  const getDisabledItemStatus = useCallback(
    (itemId, maxSelected) => {
      const isMaxSelections = selected.length >= maxSelected;

      if (isMaxSelections) {
        const isSelected = selected.find((item) => item.itemId === itemId);

        return !isSelected;
      }

      return false;
    },
    [selected],
  );

  useEffect(() => {
    handleSetModifiersTotal(modifierId, modifierItems, selected);
  }, [handleSetModifiersTotal, modifierId, modifierItems, selected]);

  return (
    <div className="menu__modal__modifier" data-cy={cySelectors.MODIFIERS_CHECKBOXES}>
      <fieldset>
        <legend>{label}</legend>
        <input
          type="hidden"
          name={`modifiers.${modifierIndex}.modifierId`}
          {...register(`modifiers.${modifierIndex}.modifierId`)}
          value={modifierId}
        />
        <small className="menu__modal__modifier__hint">({modifierHint})</small>

        {modifierItems.map(({ itemName, itemPrice, itemId }) => {
          // Declaration needed beforehand as we want to run register's methods + custom ones
          const modifierItem = register(`modifiers.${modifierIndex}.modifierItems`);
          const formattedPrice = formatCurrency(itemPrice);
          const id = `${modifierIndex}-${itemId}`;

          return (
            <div
              key={itemName}
              className="menu__modal__modifier__choice"
              data-cy={cySelectors.MODIFIERS_CHECKBOX}
            >
              <label htmlFor={id}>{itemName}</label>
              {!!itemPrice && (
                <span className="menu__modal__modifier__choice__price">+{formattedPrice}</span>
              )}
              <input
                onChange={(event) => {
                  modifierItem.onChange(event);
                  handleOnChange(itemId);
                }}
                onBlur={modifierItem.onBlur}
                ref={modifierItem.ref}
                type="checkbox"
                id={id}
                name={`modifiers.${modifierIndex}.modifierItems`}
                disabled={getDisabledItemStatus(itemId, maxSelections)}
                value={itemId}
                checked={getCheckedItemStatus(itemId)}
              />
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default MenuModalModifierCheckbox;
