import React from 'react';

import FormField from '../../../../components/FormField';
import FormFieldSelect from '../../../../components/FormFieldSelect';

import cySelectors from '../../../../tests/cySelectors';

const DetailsFormPhoneField = ({ phone, countryCode, countryCodes, defaultCountryCodeOption }) => (
  <div className="details__form__phone__field">
    <p className="details__form__phone__label">Your mobile number</p>
    <small className="details__form__phone__help-text">So we can find you</small>

    <div className="details__form__phone__fields">
      <FormFieldSelect
        name="countryCode"
        defaultValue={defaultCountryCodeOption}
        prefillValue={countryCode}
        options={countryCodes}
        placeholder="Your country code"
        extraClasses="details__form__phone__select"
        showValueAsLabel
      />
      <FormField
        name="phone"
        type="number"
        prefillValue={phone}
        placeholder="Your mobile number"
        autocomplete="tel-national"
        data-cy={cySelectors.DETAILS_FORM_PHONE}
      />
    </div>
  </div>
);

export default DetailsFormPhoneField;
