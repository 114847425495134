import React from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
  const { data: venueData } = useSelector((state) => state.venue);
  const title = venueData?.name || 'ServedUp';

  return (
    <div className="header">
      <span className="header__title">{title}</span>
    </div>
  );
};

export default Header;
