import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import Logger from '../utils/Logger';

const supportedPaymentMethods = {
  APPLE_PAY: 'https://apple.com/apple-pay',
  GOOGLE_PAY: 'https://google.com/pay',
  BASIC_CARD: 'basic-card',
};

const useSupportedPaymentMethod = ({ paymentMethod }) => {
  const [paymentMethodEnabled, setPaymentMethodEnabled] = useState(true);
  const defaultInstrument = useMemo(
    () => ({
      supportedMethods: paymentMethod,
    }),
    [paymentMethod],
  );
  // This is an undocumented equirement in order to be able to show the google button as per:
  // https://developers.google.com/web/fundamentals/payments/merchant-guide/deep-dive-into-payment-request
  const googleInstrumentData = useMemo(
    () => ({
      data: {
        environment: 'TEST',
      },
    }),
    [],
  );
  const instruments = useMemo(
    () => ({
      [supportedPaymentMethods.APPLE_PAY]: defaultInstrument,
      [supportedPaymentMethods.BASIC_CARD]: defaultInstrument,
      [supportedPaymentMethods.GOOGLE_PAY]: { ...defaultInstrument, ...googleInstrumentData },
    }),
    [defaultInstrument, googleInstrumentData],
  );

  const instrument = useMemo(() => instruments[paymentMethod], [instruments, paymentMethod]);

  const PaymentRequestConstructor = window.PaymentRequest;
  // details is required, even if not used, to check payment support
  // through canMakePayment().
  const details = useMemo(
    () => ({
      total: {
        label: 'Total',
        amount: {
          currency: 'GBP',
          value: '0.00',
        },
      },
    }),
    [],
  );

  const handleCanMakePayment = useCallback(async () => {
    const supportedInstruments = [instrument];

    try {
      const isSupportedPaymentMethod = new PaymentRequestConstructor(
        supportedInstruments,
        details,
      ).canMakePayment();

      return await isSupportedPaymentMethod;
    } catch (error) {
      Logger.error(error);

      return false;
    }
  }, [PaymentRequestConstructor, details, instrument]);

  useLayoutEffect(() => {
    // This is needed as safari complains about PaymentRequest being undefined at eval()
    // also doesn't currently exist in Firefox
    if (PaymentRequestConstructor) {
      (async () => {
        const isPaymentMethodEnabled = await handleCanMakePayment(paymentMethod);

        setPaymentMethodEnabled(isPaymentMethodEnabled);
      })();
    }
  }, [handleCanMakePayment, paymentMethod, PaymentRequestConstructor]);

  return paymentMethodEnabled;
};

export { supportedPaymentMethods, useSupportedPaymentMethod };
