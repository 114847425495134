import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import LoadingSpinner from '../../components/LoadingSpinner';
import { isProd } from '../../utils/index';

const ErrorFallback = ({ error, _componentStack, resetError }) => {
  console.log('ErrorFallback error: ', error);
  const history = useHistory();
  const { redirect, name, message } = error || {};
  const { url, text, hide } = redirect || {};
  const redirectText = text || 'Try again';
  const isServedUpError = name === 'ServedUpError';
  const isChunkLoadError = name === 'ChunkLoadError';
  const errorMessage =
    isProd && !isServedUpError
      ? `Looks like someone turned off all the taps, We've let someone know.`
      : message;

  const handleRedirect = useCallback(() => {
    resetError();
    window.location.href = url;
  }, [url, resetError]);

  const handleDefaultRedirect = useCallback(() => {
    history?.go(0);
    resetError();
  }, [history, resetError]);

  const handleChunkLoadError = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    if (isChunkLoadError) {
      handleChunkLoadError();
    }
  }, [handleChunkLoadError, isChunkLoadError]);

  if (isChunkLoadError) {
    return <LoadingSpinner center />;
  }

  return (
    <div className="error-fallback">
      <p className="error-fallback__message">{errorMessage}</p>

      {!hide && (
        <div className="error-fallback__action">
          {!url ? (
            <Button onClick={handleDefaultRedirect}>{redirectText}</Button>
          ) : (
            <Button onClick={handleRedirect}>{redirectText}</Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ErrorFallback;
