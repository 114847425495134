import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import formatCurrency from '../../../utils/formatCurrency';
import CheckoutContext from '../CheckoutContext';

import cySelectors from '../../../tests/cySelectors';

const ServiceCharge = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const { data: venueData } = useSelector((state) => state.venue);
  const { serviceSubtotal, serviceRate } = orderData || {};
  const { serviceChargeLabel, serviceCharge } = venueData || {};
  const isServiceChargeMandatory = serviceCharge === 'MANDATORY';
  const { isServiceChargeEnabled, setIsServiceChargeEnabled } = useContext(CheckoutContext);

  const label = serviceChargeLabel || 'Add service charge';
  const formattedServiceSubtotal = formatCurrency(serviceSubtotal);

  const handleToggleServiceCharge = () => {
    setIsServiceChargeEnabled(!isServiceChargeEnabled);
  };

  return (
    <div className="service-charge">
      {!isServiceChargeMandatory && (
        <div className="service-charge__checkbox">
          <input
            type="checkbox"
            defaultChecked={isServiceChargeEnabled}
            onClick={handleToggleServiceCharge}
            data-cy={cySelectors.CHECKOUT_SERVICE_CHARGE}
          />
        </div>
      )}
      <div className="service-charge__label">
        <p>
          {label} ({serviceRate}%)
        </p>
      </div>
      <div className="service-charge__subtotal">{formattedServiceSubtotal}</div>
    </div>
  );
};

export default ServiceCharge;
