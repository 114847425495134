import React from 'react';
import clsx from 'clsx';

const LoadingSpinner = ({ center }) => {
  const classes = clsx('loading-spinner', {
    'loading-spinner--centered': center,
  });

  return <div className={classes} />;
};

export default LoadingSpinner;
