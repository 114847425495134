import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InlineForm from '../../../components/InlineForm';
import useAsyncServedUpError from '../../../hooks/useAsyncServedUpError';
import { addPromoCode } from '../../../store/slices/order';

const PromoCode = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const { orderId, venueId } = orderData;
  const dispatch = useDispatch();
  const throwError = useAsyncServedUpError();

  const field = useMemo(
    () => ({
      name: 'promoCode',
      label: 'Add promo code',
      placeholder: 'Your promo code',
      helpText: '',
    }),
    [],
  );

  const handleSubmit = useCallback(
    async (promoCode) => {
      const payload = { venueId, promoCode };

      try {
        await dispatch(addPromoCode({ params: orderId, payload })).unwrap();
      } catch (error) {
        throwError({ error, venueId, orderId });
      }
    },
    [dispatch, throwError, orderId, venueId],
  );

  return <InlineForm handleInlineSubmit={handleSubmit} field={field} />;
};

export default PromoCode;
