import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Venue from '../../pages/Venue';
import Menu from '../../pages/Menu';
import Review from '../../pages/Review';
import Details from '../../pages/Details';
import Checkout from '../../pages/Checkout';
import Confirmation from '../../pages/Confirmation';

import { store } from '../../store';

import Head from '../Head';
import Header from '../Header';
import ChallengeSuccess from '../../pages/Challenge/ChallengeSuccess';
import ChallengeFailure from '../../pages/Challenge/ChallengeFailure';

const App = () => (
  <Provider store={store}>
    <HelmetProvider>
      <div className="App">
        <Head />
        <Header />
        <Router>
          <Switch>
            <Route exact path="/challenge_success" component={ChallengeSuccess} />
            <Route exact path="/challenge_failure" component={ChallengeFailure} />
            <Route exact path="/menu/:venueId/:orderId?" component={Menu} />
            <Route exact path="/:venuePath/:tableName?" component={Venue} />

            <Route exact path="/review/:venueId/:orderId" component={Review} />
            <Route exact path="/details/:venueId/:orderId" component={Details} />
            <Route exact path="/checkout/:venueId/:orderId" component={Checkout} />
            <Route
              exact
              path="/checkout-epos/:venueId/:orderId"
              component={(props) => <Checkout isQrPayment {...props} />}
            />
            <Route exact path="/confirmation/:venueId/:orderId" component={Confirmation} />
          </Switch>
        </Router>
      </div>
    </HelmetProvider>
  </Provider>
);

export default App;
