import * as yup from 'yup';

const DetailsFormSchema = yup.object().shape({
  tableName: yup.object().typeError('Please select a table').required(),
  name: yup.string().required(),
  email: yup.string().trim().email().required(),
  phone: yup
    .string()
    .matches(new RegExp(/(\d|\s)+/))
    .required(),
});

export default DetailsFormSchema;
