import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import InlineFormField from '../InlineFormField';
import Button from '../Button';

import Logger from '../../utils/Logger';

const inlineFormValidationStatuses = {
  NONE: 'NONE',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

const InlineForm = ({ field, handleInlineSubmit }) => {
  const [inlineValidationStatus, setInlineValidationStatus] = useState(
    inlineFormValidationStatuses.NONE,
  );
  const { getValues, setError } = useFormContext();
  const { name, label, helpText, placeholder } = field;
  const isInlineValidationSuccessful =
    inlineValidationStatus === inlineFormValidationStatuses.SUCCESS;
  const isInlineValidationFail = inlineValidationStatus === inlineFormValidationStatuses.FAIL;

  const handleSubmit = useCallback(async () => {
    try {
      setInlineValidationStatus(inlineFormValidationStatuses.NONE);

      const inlineValue = getValues(name);

      await handleInlineSubmit(inlineValue);

      setInlineValidationStatus(inlineFormValidationStatuses.SUCCESS);
    } catch (error) {
      Logger.error(error);
      setInlineValidationStatus(inlineFormValidationStatuses.FAIL);

      setError(name, {
        type: 'manual',
      });
    }
  }, [getValues, handleInlineSubmit, name, setError]);

  const handleResetValidationOnFocus = useCallback(() => {
    if (isInlineValidationSuccessful || isInlineValidationFail) {
      setInlineValidationStatus(inlineFormValidationStatuses.NONE);
    }
  }, [isInlineValidationFail, isInlineValidationSuccessful, setInlineValidationStatus]);

  return (
    <div className="inline-form">
      <InlineFormField
        name={name}
        label={label}
        helpText={helpText}
        placeholder={placeholder}
        validationStatus={inlineValidationStatus}
        handleResetValidationOnFocus={handleResetValidationOnFocus}
      />
      <Button disabled={isInlineValidationSuccessful} onClick={handleSubmit}>
        Apply
      </Button>
    </div>
  );
};

export default InlineForm;
