import React from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../components/Button';

import formatCurrency from '../../../utils/formatCurrency';

import { ReactComponent as MinusIcon } from '../../../assets/images/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';

import cySelectors from '../../../tests/cySelectors';

const MenuModalFooter = ({ addToCartTotal, quantity, increaseQuantity, decreaseQuantity }) => {
  const { loading: orderLoading } = useSelector((state) => state.order);
  const formattedAddToCartTotal = formatCurrency(addToCartTotal);

  return (
    <footer className="menu__modal__footer">
      <div className="menu__modal__footer__quantity-modifiers">
        <button
          className="menu__modal__footer__quantity-modifier menu__modal__footer__quantity-modifier--minus"
          disabled={quantity === 1}
          onClick={decreaseQuantity}
          type="button"
          data-cy={cySelectors.MODIFIERS_DECREASE_BTN}
        >
          <MinusIcon />
        </button>
        <span className="menu__modal__footer__count">{quantity}</span>
        <button
          className="menu__modal__footer__quantity-modifier menu__modal__footer__quantity-modifier--plus"
          onClick={increaseQuantity}
          type="button"
          data-cy={cySelectors.MODIFIERS_INCREASE_BTN}
        >
          <PlusIcon />
        </button>
      </div>
      <div className="menu__modal__footer__btn">
        <Button
          fullWidth
          type="submit"
          disabled={orderLoading}
          loading={orderLoading}
          data-cy={cySelectors.MODIFIERS_SUBMIT_BTN}
        >
          <span>Add to order</span>
          <span>{formattedAddToCartTotal}</span>
        </Button>
      </div>
    </footer>
  );
};

export default MenuModalFooter;
