import React from 'react';
import { useSelector } from 'react-redux';
import getCategories from '../../../utils/getCategories';

const MenuNavigation = ({ handleScrollToCategory }) => {
  const {
    data: menuData,
    error: _menuError,
    loading: _menuLoading,
  } = useSelector((state) => state.menu);
  const categories = getCategories(menuData.menu);

  return (
    <nav className="menu__navigation">
      <ul>
        {categories.map(({ categoryName, categoryId }) => (
          <li key={categoryId}>
            <button type="button" onClick={() => handleScrollToCategory(categoryId)}>
              {categoryName}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuNavigation;
