import React, { useEffect, useCallback } from 'react';
import qs from 'query-string';

const ChallengeFailure = () => {
  const handlePostSidMessage = useCallback((sid) => {
    window.parent.postMessage({ sid }, window.location.origin);
  }, []);

  useEffect(() => {
    const sid = qs.parse(window.location.search)['cko-session-id'];

    if (sid) {
      handlePostSidMessage(sid);
    }
  }, [handlePostSidMessage]);

  return (
    <div>
      <p>Challenge failure.</p>
    </div>
  );
};

export default ChallengeFailure;
