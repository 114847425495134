import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const beforeSendFiltering = (event) => {
  const isServedUpError = event.exception.values.some((error) => error.type === 'ServedUpError');
  return isServedUpError ? null : event;
};

const initSentry = (isDev, isLocal) => {
  if (!isLocal) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_PUBLIC_KEY,
      beforeSend: beforeSendFiltering,
      environment: isDev ? 'development' : 'production',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: isDev ? 1.0 : 0.2,
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
    });
  }
};

export default initSentry;
