import React from 'react';
import { useSelector } from 'react-redux';

import InfoBanner from '../InfoBanner';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';

const ServiceStatus = () => {
  const { data: menuData } = useSelector((state) => state.menu);
  const { serviceStatus } = menuData || {};
  const { drinksDelay, foodDelay, isDrinksServiceOn, isFoodServiceOn } = serviceStatus || {};
  const hasDelay = !!drinksDelay || !!foodDelay;
  const hasDrinkServiceAndIsDelayed = !!drinksDelay && isDrinksServiceOn;
  const hasFoodServiceAndIsDelayed = !!foodDelay && isFoodServiceOn;

  return (
    <InfoBanner Icon={ClockIcon} title="We currently have a minimum process time for orders.">
      {!isFoodServiceOn && <p>The Kitchen is currently closed and not accepting Food orders.</p>}
      {!isDrinksServiceOn && <p>The Bar is currently closed and not accepting Drink orders.</p>}
      {hasDelay && (
        <>
          {hasFoodServiceAndIsDelayed && <p>Food - {foodDelay} minutes</p>}
          {hasDrinkServiceAndIsDelayed && <p>Drinks - {drinksDelay} minutes</p>}
        </>
      )}
    </InfoBanner>
  );
};

export default ServiceStatus;
