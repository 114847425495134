import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import Title from '../../components/Title';
import LoadingSpinner from '../../components/LoadingSpinner';
import BackButton from '../../components/BackButton';

import DetailsForm from './DetailsForm';

import { patchOrder } from '../../store/slices/order';

import getHumanReadableErrorMessage from '../../utils/getHumanReadableErrorMessage';
import errorCodes from '../../utils/errorCodes';
import useAsyncServedUpError from '../../hooks/useAsyncServedUpError';

const Details = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: orderData } = useSelector((state) => state.order);
  const params = useParams();
  const throwError = useAsyncServedUpError();

  const backButtonRoute = `/review/${params.venueId}/${params.orderId}`;

  const handleNavigateToCheckoutPage = useCallback(
    (venueId, orderId) => {
      history.push(`/checkout/${venueId}/${orderId}`);
    },
    [history],
  );

  const handlePatchDetails = useCallback(
    async (values, venueId, orderId, setPatchDetailsError, setError) => {
      const payload = {
        venueId,
        ...values,
      };

      try {
        await dispatch(patchOrder({ params: orderId, payload })).unwrap();
        setPatchDetailsError(false);

        handleNavigateToCheckoutPage(venueId, orderId);
      } catch (error) {
        const errorCode = error.message;
        const isInvalidPhone = errorCode === errorCodes.INVALID_PHONE;

        if (isInvalidPhone) {
          const { message: humanReadableError } = getHumanReadableErrorMessage(errorCode);

          setError('phone', {
            type: 'manual',
            message: humanReadableError,
          });
        } else {
          throwError({ error, venueId, orderId });
        }

        setPatchDetailsError(true);
      }
    },
    [dispatch, handleNavigateToCheckoutPage, throwError],
  );

  return orderData ? (
    <div className="details">
      <BackButton toRoute={backButtonRoute} />
      <Title main="About you" />
      <DetailsForm handlePatchDetails={handlePatchDetails} />
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Details);
