import React, { useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { FormContext } from '../Form';

// after prefill trigger a validate field
const FormField = ({
  name,
  label,
  helpText,
  placeholder,
  type = 'text',
  prefillValue,
  autocomplete = 'on',
  disabled,
  extraClasses,
  children,
  ...extraProps
}) => {
  const {
    register,
    getValues,
    setValue,
    formState: { dirtyFields, errors },
    trigger,
  } = useFormContext();
  const { validateOnLoad } = useContext(FormContext);

  const isFieldDirty = dirtyFields[name];
  const isValidateOnLoad = !isFieldDirty && getValues(name) && validateOnLoad;
  const isText = type === 'text';
  const isNumber = type === 'number';
  const classes = clsx(
    {
      [extraClasses]: extraClasses,
    },
    'form__field',
  );

  useEffect(() => {
    if (prefillValue) {
      setValue(name, prefillValue);
    }
  }, [name, prefillValue, setValue]);

  useEffect(() => {
    if (isValidateOnLoad) {
      trigger(name);
    }
  }, [isValidateOnLoad, name, trigger]);

  return (
    <div className={classes}>
      {label && (
        <label className="form__field__label" htmlFor={name}>
          {label}
        </label>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}
      {isText && (
        <input
          type="text"
          placeholder={placeholder}
          {...register(name)}
          {...extraProps}
          id={name}
          name={name}
          aria-invalid={errors[name] ? 'true' : 'false'}
          autoComplete={autocomplete}
          disabled={disabled}
        />
      )}
      {isNumber && (
        <input
          type="number"
          placeholder={placeholder}
          {...register(name)}
          {...extraProps}
          id={name}
          name={name}
          aria-invalid={errors[name] ? 'true' : 'false'}
          autoComplete={autocomplete}
        />
      )}
      {errors[name] && <div className="form__field__error">{errors[name].message}</div>}
    </div>
  );
};

export default FormField;
