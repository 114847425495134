import React from 'react';
import formatCurrency from '../../../utils/formatCurrency';

const Discounts = ({ discounts }) => (
  <div className="discounts">
    {discounts.map((discountItem) => (
      <Discount key={discountItem.promoCode} discountItem={discountItem} />
    ))}
  </div>
);

const Discount = ({ discountItem }) => {
  const { offer, promoCode, discount } = discountItem;

  const formattedDiscount = formatCurrency(discount);

  return (
    <div key={promoCode} className="discount">
      <span>
        {offer} ({promoCode})
      </span>
      <span>-{formattedDiscount}</span>
    </div>
  );
};

export default Discounts;
