import getHumanReadableErrorMessage from './getHumanReadableErrorMessage';

class ServedUpError extends Error {
  constructor({ error, venueId, orderId }) {
    super();
    const { message, redirect, code } = getHumanReadableErrorMessage(error, venueId, orderId);
    this.code = code;
    this.message = message;
    this.redirect = redirect;
    this.name = 'ServedUpError';
  }
}

export default ServedUpError;
