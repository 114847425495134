import { combineReducers, configureStore } from '@reduxjs/toolkit';

import venue from './slices/venue';
import order from './slices/order';
import menu from './slices/menu';
import cart from './slices/cart';
import tables from './slices/tables';
import checkout from './slices/checkout';
import pickupTimes from './slices/pickup-times';

const reducers = combineReducers({ venue, order, menu, cart, tables, checkout, pickupTimes });

const store = configureStore({
  reducer: reducers,
});

export { store, reducers };
