import React, { Suspense, lazy, memo } from 'react';

import {
  supportedPaymentMethods,
  useSupportedPaymentMethod,
} from '../../../hooks/useSupportedPaymentMethod';

import LoadingSpinner from '../../../components/LoadingSpinner';

const CheckoutStripe = lazy(() => import('../CheckoutStripe'));
const CheckoutComCard = lazy(() => import('../CheckoutComCard'));
const CheckoutComGooglePay = lazy(() => import('../CheckoutComGooglePay'));
const CheckoutComApplePay = lazy(() => import('../CheckoutComApplePay'));

const PaymentSystems = ({ systems = [] }) => {
  const isGooglePaySupported = useSupportedPaymentMethod({
    paymentMethod: supportedPaymentMethods.GOOGLE_PAY,
  });
  const isApplePaySupported = !!window.ApplePaySession;
  const isCheckoutCom = systems.includes('CHECKOUT');
  const isStripe = systems.includes('STRIPE');

  return (
    <Suspense fallback={<LoadingSpinner />}>
      {isCheckoutCom && (
        <>
          <CheckoutComCard />
          {isGooglePaySupported && <CheckoutComGooglePay />}
          {isApplePaySupported && <CheckoutComApplePay />}
        </>
      )}
      {isStripe && <CheckoutStripe />}
    </Suspense>
  );
};

export default memo(PaymentSystems);
