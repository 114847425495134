import React from 'react';
import InfoBanner, { infoBannerLevels } from '../InfoBanner';

import { ReactComponent as Clock } from '../../assets/images/clock.svg';

const VenueTakeAwayWarning = () => (
  <InfoBanner Icon={Clock} title="We're closed!" level={infoBannerLevels.WARN}>
    <p>But you can still order a scheduled takeaway.</p>
  </InfoBanner>
);

export default VenueTakeAwayWarning;
