import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory, useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchVenue } from '../store/slices/venue';
import { fetchPickupTimes } from '../store/slices/pickup-times';
import { createOrder, fetchOrder } from '../store/slices/order';
import { updateCart } from '../store/slices/cart';
import { fetchMenu } from '../store/slices/menu';
import { fetchTables } from '../store/slices/tables';
import useAsyncServedUpError from '../hooks/useAsyncServedUpError';

// TODO: pass params as props to Component and refactor to use them (maybe)
// TODO: refactor the checks of current path and params to use matchPath instead like isReviewRoute
// TODO: refactor handleRoutes() functions in each own file passing
// arguments to the useCallback hooks to be pure functions and reduce this function's cognitive
// complexity.
const withHandleRoutesDispatch = (Component) => (props) => {
  const { venuePath, orderId, venueId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const throwError = useAsyncServedUpError();

  const {
    data: venueData,
    loading: venueLoading,
    error: venueError,
  } = useSelector((state) => state.venue);
  const {
    data: orderData,
    loading: orderLoading,
    error: orderError,
  } = useSelector((state) => state.order);
  const { data: menuData, loading: menuLoading, menuError } = useSelector((state) => state.menu);
  const {
    data: tablesData,
    loading: tablesLoading,
    tablesError,
  } = useSelector((state) => state.tables);
  const {
    data: pickupTimesData,
    loading: pickupTimesLoading,
    pickupTimesError,
  } = useSelector((state) => state.pickupTimes);
  const { isTakeawayEnabled } = venueData || {};
  const { paymentMethod, paymentService } = orderData || {};
  const isPathNameMenuRoute = new RegExp(/^\/menu\//).test(pathname);

  const isReviewRoute = matchPath(pathname, {
    path: '/review/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isVenueRoute =
    !isPathNameMenuRoute &&
    matchPath(pathname, {
      path: '/:venuePath/:tableName?',
      exact: true,
      strict: true,
    });
  const isMenuRoute = matchPath(pathname, {
    path: '/menu/:venueId/:orderId?',
    exact: true,
    strict: true,
  });
  const isCheckoutRoute = matchPath(pathname, {
    path: '/(checkout|checkout-epos)/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isDetailsRoute = matchPath(pathname, {
    path: '/details/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isConfirmationRoute = matchPath(pathname, {
    path: '/confirmation/:venueId/:orderId',
    exact: true,
    strict: true,
  });

  const handleErrors = useCallback(() => {
    switch (true) {
      case !!menuError:
        throwError({ error: menuError, venueId, orderId });
        break;
      case !!orderError:
        throwError({ error: orderError, venueId, orderId });
        break;
      case !!venueError:
        throwError({ error: venueError, venueId, orderId });
        break;
      case !!tablesError:
        throwError({ error: tablesError, venueId, orderId });
        break;
      case !!pickupTimesError:
        throwError({ error: pickupTimesError, venueId, orderId });
        break;
      default:
        break;
    }
  }, [
    menuError,
    orderError,
    venueError,
    tablesError,
    pickupTimesError,
    venueId,
    orderId,
    throwError,
  ]);

  const handleFetchOrder = useCallback(
    (params) => {
      const { forceFetch } = params || {};
      if ((!orderData && !orderLoading) || forceFetch) {
        dispatch(fetchOrder({ params: orderId, query: { venueId } }));
      }
    },
    [dispatch, orderData, orderId, orderLoading, venueId],
  );

  const handleFetchVenueFromId = useCallback(() => {
    if (!venueData && !venueLoading && venueId) {
      dispatch(fetchVenue({ params: venueId }));
    }
  }, [dispatch, venueData, venueId, venueLoading]);

  const handleFetchVenueFromPath = useCallback(() => {
    if (!venueData && !venueLoading && venuePath) {
      dispatch(fetchVenue({ params: venuePath }));
    }
  }, [dispatch, venueData, venueLoading, venuePath]);

  const handleReviewRoute = useCallback(() => {
    if (!orderData) {
      handleFetchOrder();
    }
    if (!venueData) {
      handleFetchVenueFromId();
    }
  }, [handleFetchOrder, handleFetchVenueFromId, orderData, venueData]);

  const handleFetchTables = useCallback(() => {
    if (!tablesData && !tablesLoading) {
      dispatch(fetchTables({ params: venueId }));
    }
  }, [dispatch, tablesData, tablesLoading, venueId]);

  const handleFetchPickupTimes = useCallback(() => {
    if (!pickupTimesData && !pickupTimesLoading) {
      dispatch(fetchPickupTimes({ params: venueId }));
    }
  }, [dispatch, pickupTimesData, pickupTimesLoading, venueId]);

  const handleCreateOrder = useCallback(() => {
    if (!orderData && !orderLoading && venueId) {
      dispatch(createOrder({ payload: { venueId } }));
    }
  }, [dispatch, orderData, orderLoading, venueId]);

  const handleFetchMenu = useCallback(() => {
    if (!menuData && !menuLoading && venueId) {
      dispatch(fetchMenu({ params: venueId }));
    }
  }, [dispatch, menuData, menuLoading, venueId]);

  const handleVenueRoute = useCallback(() => {
    handleFetchVenueFromPath();
    if (venueData) {
      history.push(`/menu/${venueData.venueId}`);
    }
  }, [handleFetchVenueFromPath, history, venueData]);

  const handleMenuRoute = useCallback(() => {
    handleFetchVenueFromId();
    handleFetchMenu();
    if (orderId) {
      handleFetchOrder();
    } else {
      handleCreateOrder();
    }

    if (venueData && orderData && !orderId) {
      history.push(`/menu/${venueData.venueId}/${orderData.orderId}`);
    }
  }, [
    handleCreateOrder,
    handleFetchMenu,
    handleFetchOrder,
    handleFetchVenueFromId,
    history,
    orderData,
    orderId,
    venueData,
  ]);

  const handleCheckoutRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchVenueFromId();

    if (isTakeawayEnabled) {
      handleFetchPickupTimes();
    }
  }, [handleFetchOrder, handleFetchPickupTimes, handleFetchVenueFromId, isTakeawayEnabled]);

  const handleConfirmationRoute = useCallback(() => {
    if (venueId && orderId && (!paymentMethod || !paymentService)) {
      // TODO: Maybe we can avoid the forceFetch with <Route forceRefresh={true} /> in /confirmation
      handleFetchOrder({ forceFetch: true });
    }
  }, [handleFetchOrder, orderId, paymentMethod, paymentService, venueId]);

  const handleDetailsRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchVenueFromId();
    handleFetchTables();
  }, [handleFetchOrder, handleFetchTables, handleFetchVenueFromId]);

  const handleCartUpdate = useCallback(() => {
    const { cart = [] } = orderData;
    try {
      dispatch(updateCart(cart));
    } catch (error) {
      throwError({ error, venueId, orderId });
    }
  }, [dispatch, throwError, orderData, venueId, orderId]);

  useEffect(() => {
    if (isVenueRoute) handleVenueRoute();
  }, [handleVenueRoute, isVenueRoute, pathname, venuePath]);

  useEffect(() => {
    if (isReviewRoute) handleReviewRoute();
  }, [handleReviewRoute, isReviewRoute]);

  useEffect(() => {
    if (isMenuRoute) handleMenuRoute();
  }, [handleMenuRoute, isMenuRoute]);

  useEffect(() => {
    if (isCheckoutRoute) handleCheckoutRoute();
  }, [handleCheckoutRoute, isCheckoutRoute]);

  useEffect(() => {
    if (isDetailsRoute) handleDetailsRoute();
  }, [handleDetailsRoute, isDetailsRoute]);

  useEffect(() => {
    if (isConfirmationRoute) handleConfirmationRoute();
  }, [handleConfirmationRoute, isConfirmationRoute]);

  useEffect(() => {
    if (orderData) handleCartUpdate();
  }, [handleCartUpdate, orderData]);

  useEffect(() => {
    handleErrors();
  }, [handleErrors]);

  return <Component {...props} />;
};
export default withHandleRoutesDispatch;
