import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';

const MenuModalModifierRadio = ({ modifier, modifierIndex, handleSetModifiersTotal }) => {
  const { register, setValue } = useFormContext();
  const { label, modifierId, modifierItems } = modifier;
  const [selected, setSelected] = useState('');

  const handleOnChange = (value) => {
    setSelected(value);
  };

  const preselectFirstOption = useCallback(() => {
    const [firstOption] = modifierItems;
    const { itemId } = firstOption;

    setSelected(itemId);
  }, [modifierItems]);

  const presetFirstOptionValue = useCallback(() => {
    const value = { modifierId, modifierItems: selected };

    setValue(`modifiers.${modifierIndex}`, value);
  }, [modifierId, modifierIndex, selected, setValue]);

  const getCheckedItemStatus = (itemId, selectedItem) => itemId === selectedItem;

  useEffect(() => {
    preselectFirstOption();
  }, [preselectFirstOption]);

  useEffect(() => {
    presetFirstOptionValue();
  }, [presetFirstOptionValue]);

  useEffect(() => {
    if (selected) {
      handleSetModifiersTotal(modifierId, modifierItems, selected);
    }
  }, [handleSetModifiersTotal, modifierId, modifierItems, selected]);

  return (
    <div className="menu__modal__modifier" data-cy={cySelectors.MODIFIERS_RADIOS}>
      <fieldset>
        <legend>{label}</legend>
        <input
          type="hidden"
          name={`modifiers.${modifierIndex}.modifierId`}
          {...register(`modifiers.${modifierIndex}.modifierId`)}
          value={modifierId}
        />

        {modifierItems.map(({ itemName, itemPrice, itemId }) => {
          // Declaration needed beforehand as we want to run register's methods + custom ones
          const modifierItem = register(`modifiers.${modifierIndex}.modifierItems`);
          const formattedPrice = formatCurrency(itemPrice);
          const id = `${modifierIndex}-${itemId}`;

          return (
            <div
              key={itemName}
              className="menu__modal__modifier__choice"
              data-cy={cySelectors.MODIFIERS_RADIO}
            >
              <label htmlFor={id}>{itemName}</label>
              {!!itemPrice && (
                <span className="menu__modal__modifier__choice__price">+{formattedPrice}</span>
              )}
              <input
                onChange={(event) => {
                  modifierItem.onChange(event);
                  handleOnChange(itemId);
                }}
                onBlur={modifierItem.onBlur}
                ref={modifierItem.ref}
                type="radio"
                id={id}
                name={`modifiers.${modifierIndex}.modifierItems`}
                value={itemId}
                checked={getCheckedItemStatus(itemId, selected)}
              />
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default MenuModalModifierRadio;
