import errorCodes from '../../../utils/errorCodes';

const getUniqueItemId = (o) => {
  let encoded;
  try {
    // encoded = window.btoa(JSON.stringify(o));
    encoded = window.btoa(unescape(encodeURIComponent(JSON.stringify(o))));
  } catch (error) {
    throw new Error(errorCodes.PROBLEM_ENCODING_ITEM);
  }
  return encoded;
};

// TODO update backend to be able to collate quantities in BE
const getAddToCartPayload = (cart, newItem, newItemQuantity) => {
  const updatedCart = [...cart];
  const { modifiers, itemOption } = newItem;
  const sanitizedNewItem = {
    ...newItem,
    modifiers: modifiers ? Object.values(modifiers) : [],
    itemOption: itemOption.replace(/\s/g, '_').toUpperCase(),
  };
  const newUniqItemId = getUniqueItemId(sanitizedNewItem);
  const targetItemIndex = updatedCart.findIndex(({ uniqItemId }) => uniqItemId === newUniqItemId);
  const targetItem = updatedCart[targetItemIndex];

  if (targetItem) {
    updatedCart[targetItemIndex] = {
      ...targetItem,
      quantity: targetItem.quantity + newItemQuantity,
    };

    return updatedCart;
  }

  const newItemPayload = {
    quantity: newItemQuantity,
    uniqItemId: newUniqItemId,
  };

  return [...cart, newItemPayload];
};

export default getAddToCartPayload;
