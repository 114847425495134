import React from 'react';

const Title = ({ kicker, main }) => (
  <div className="title">
    {kicker && <div className="title-kicker">{kicker}</div>}
    <h1 className="title-heading">{main}</h1>
  </div>
);

export default Title;
