import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncServedUpError from '../../hooks/useAsyncServedUpError';

import { changeCartItemQuantity } from '../../store/slices/order';

import CartItem from '../CartItem';

const CartItems = ({ editable = false }) => {
  const dispatch = useDispatch();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { data: cartData } = useSelector((state) => state.cart);
  const { venueId } = venueData || {};
  const { orderId } = orderData || {};
  const throwError = useAsyncServedUpError();

  const handleNewItemQuantity = useCallback(
    async ({ uniqItemId, quantityChange, isDelete }) => {
      const payload = {
        venueId,
        uniqItemId,
        quantityChange,
        isDelete,
        cart: cartData,
      };
      try {
        await dispatch(changeCartItemQuantity({ params: orderId, payload })).unwrap();
      } catch (error) {
        throwError({ error, venueId, orderId });
      }
    },
    [dispatch, throwError, venueId, cartData, orderId],
  );

  return (
    <div className="cart__items">
      {orderData.cart.map((cartItem) => {
        const key = cartItem.uniqItemId;

        return (
          <CartItem
            key={key}
            cartItem={cartItem}
            handleNewItemQuantity={handleNewItemQuantity}
            isSubmitting={orderLoading}
            editable={editable}
          />
        );
      })}
    </div>
  );
};

export default CartItems;
