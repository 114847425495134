import { createEnhancedAsynThunk, sliceHandler } from '../../utils';

const sliceArgs = {
  SLICE_NAME: 'pickup-times',
  ENDPOINT: 'venues',
  RESOURCE: 'pickup-times',
};

const fetchPickupTimes = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
  resource: sliceArgs.RESOURCE,
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchPickupTimes];

const slice = sliceHandler({ sliceName, extraReducers });
const { actions, reducer } = slice;

export const { exampleReducer } = actions;
export { fetchPickupTimes };
export default reducer;
