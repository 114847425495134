const cySelectors = {
  SUBMIT_BTN: 'SUBMIT_BTN',
  MENU_CATEGORIES: 'MENU_CATEGORIES',
  MENU_CATEGORY_ITEM: 'MENU_CATEGORY_ITEM',
  MODIFIERS_SIZES: 'MODIFIERS_SIZES',
  MODIFIERS_SIZE: 'MODIFIERS_SIZE',
  MODIFIERS_RADIOS: 'MODIFIERS_RADIOS',
  MODIFIERS_RADIO: 'MODIFIERS_RADIO',
  MODIFIERS_CHECKBOXES: 'MODIFIERS_CHECKBOXES',
  MODIFIERS_CHECKBOX: 'MODIFIERS_CHECKBOX',
  MODIFIERS_INCREASE_BTN: 'MODIFIERS_INCREASE_BTN',
  MODIFIERS_DECREASE_BTN: 'MODIFIERS_DECREASE_BTN',
  MODIFIERS_SUBMIT_BTN: 'MODIFIERS_SUBMIT_BTN',
  CART_ITEM: 'CART_ITEM',
  CART_ITEM_COUNT: 'CART_ITEM_COUNT',
  CART_ITEM_INCREASE_BTN: 'CART_ITEM_INCREASE_BTN',
  CART_ITEM_DECREASE_BTN: 'CART_ITEM_DECREASE_BTN',
  CART_REMOVE_BTN: 'CART_REMOVE_BTN',
  REVIEW_FORM_NEW_NOTES: 'REVIEW_FORM_NEW_NOTES',
  DETAILS_FORM_TABLE: 'DETAILS_FORM_TABLE',
  DETAILS_FORM_NAME: 'DETAILS_FORM_NAME',
  DETAILS_FORM_EMAIL: 'DETAILS_FORM_EMAIL',
  DETAILS_FORM_PHONE: 'DETAILS_FORM_PHONE',
  CHECKOUT_TAKEAWAY_DAY: 'CHECKOUT_TAKEAWAY_DAY',
  CHECKOUT_TAKEAWAY_TIME: 'CHECKOUT_TAKEAWAY_TIME',
  CHECKOUT_SERVICE_CHARGE: 'CHECKOUT_SERVICE_CHARGE',
  CONFIRMATION_NAME: 'CONFIRMATION_NAME',
  CONFIRMATION_EMAIL: 'CONFIRMATION_EMAIL',
  CONFIRMATION_SUBTOTAL: 'CONFIRMATION_SUBTOTAL',
  CONFIRMATION_SERVICE_CHARGE: 'CONFIRMATION_SERVICE_CHARGE',
  CONFIRMATION_TOTAL: 'CONFIRMATION_TOTAL',
  CONFIRMATION_TABLE: 'CONFIRMATION_TABLE',
  CONFIRMATION_PAID_VIA: 'CONFIRMATION_PAID_VIA',
  CONFIRMATION_TAKEAWAY_TIME: 'CONFIRMATION_TAKEAWAY_TIME',
  CONFIRMATION_NOTES: 'CONFIRMATION_NOTES',
  CONFIRMATION_SYSTEM_NOTES: 'CONFIRMATION_SYSTEM_NOTES',
};

export default cySelectors;
