import React from 'react';

import Button from '../Button';

import formatCurrency from '../../utils/formatCurrency';

import { ReactComponent as CrossCircleIcon } from '../../assets/images/cross-circle.svg';

import cySelectors from '../../tests/cySelectors';

const CartItem = ({ cartItem, handleNewItemQuantity, isSubmitting, editable }) => {
  const { itemId, price, itemName, quantity, modifiers, uniqItemId } = cartItem;
  const formattedPrice = formatCurrency(price);
  const hasModifiers = modifiers.length > 0;

  const handleChangeQuantity = (quantityChange) => {
    const payload = {
      uniqItemId,
      quantityChange,
    };

    handleNewItemQuantity(payload);
  };

  const handleRemoveItem = () => {
    const payload = {
      uniqItemId,
      quantityChange: 0,
      isDelete: true,
    };

    handleNewItemQuantity(payload);
  };

  const handleIncreaseQuantity = () => handleChangeQuantity(1);

  const handleDecreaseQuantity = () => handleChangeQuantity(-1);

  return (
    <div className="cart__item" data-cy={cySelectors.CART_ITEM}>
      <div className="cart__item__info">
        <div className="cart__item__title">
          <h3 className="cart__item__name">{itemName}</h3>
          <span className="cart__item__count" data-cy={cySelectors.CART_ITEM_COUNT}>
            {' '}
            x {quantity}
          </span>
        </div>
        {hasModifiers && (
          <div className="cart__item__modifiers">
            {modifiers.map(({ label, modifierId }) => (
              <p key={`${itemId}-${modifierId}`} className="cart__item__modifier">
                {label}
              </p>
            ))}
          </div>
        )}
      </div>
      {editable && (
        <div className="cart__item__actions">
          <Button
            disabled={isSubmitting}
            onClick={handleDecreaseQuantity}
            data-cy={cySelectors.CART_ITEM_DECREASE_BTN}
          >
            -
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={handleIncreaseQuantity}
            data-cy={cySelectors.CART_ITEM_INCREASE_BTN}
          >
            +
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={handleRemoveItem}
            extraClasses="cart__item__actions__remove"
            data-cy={cySelectors.CART_REMOVE_BTN}
          >
            <CrossCircleIcon title="Remove Item" />
          </Button>
        </div>
      )}
      <div className="cart__item__price">{formattedPrice}</div>
    </div>
  );
};

export default CartItem;
