import React from 'react';

import MenuDietaryRequirement from '../MenuDietaryRequirement';

const MenuDietaryRequirements = ({ dietaryRequirements }) => (
  <div className="menu__dietary-requirements">
    {dietaryRequirements.map((requirement) => {
      const key = requirement.requirementId;

      return <MenuDietaryRequirement key={key} requirement={requirement} />;
    })}
  </div>
);

export default MenuDietaryRequirements;
