import React from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { ReactComponent as CrossCircleIcon } from '../../assets/images/cross-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/check.svg';

const InlineFormField = ({
  name,
  label,
  helpText,
  placeholder,
  autocomplete = 'on',
  disabled,
  validationStatus,
  handleResetValidationOnFocus,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isInlineValidationSuccessful = validationStatus === 'SUCCESS';
  const isInlineValidationFail = validationStatus === 'FAIL';
  const classes = clsx('form__field', {
    'form__field--valid': isInlineValidationSuccessful,
    'form__field--invalid': isInlineValidationFail,
  });

  return (
    <div className={classes}>
      {label && (
        <label className="form__field__label" htmlFor={name}>
          {label}
        </label>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}
      <div className="form__field__input">
        <input
          {...register(name)}
          onFocus={handleResetValidationOnFocus}
          type="text"
          placeholder={placeholder}
          id={name}
          name={name}
          aria-invalid={errors[name] ? 'true' : 'false'}
          autoComplete={autocomplete}
          disabled={disabled}
        />

        <div className="form__field__input__icon">
          {isInlineValidationSuccessful && <CheckCircleIcon />}
          {isInlineValidationFail && <CrossCircleIcon />}
        </div>
      </div>
    </div>
  );
};

export default InlineFormField;
