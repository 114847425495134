import errorCodes from './errorCodes';

const getHumanReadableErrorMessage = (error, venueId, orderId) => {
  const { nextOpeningDay, nextOpeningTime, openTime, venuePath } = error || {};

  const getMessage = (errorCode) => {
    switch (errorCode) {
      case errorCodes.CAN_T_CHECKOUT_IN_THIS_STATUS:
        return `Can't checkout like this. Have you added any items to your basket?`;
      case errorCodes.INVALID_PHONE:
        return 'The provided phone number is not valid.';
      case errorCodes.INVALID_PAYMENT:
        return `Sorry there seems to be an issue paying for this order.`;
      case errorCodes.PAYMENT_FAILED:
        return 'Payment method failed. Please try another payment method.';
      case errorCodes.PAYMENT_DECLINED:
        return `Payment Declined: ${errorCode}`;
      case errorCodes.CARD_TOKENIZED_FAILURE:
        return 'Card Payment failed, please retry, or try another card or payement method.';
      case errorCodes.CLOSED:
        return `We are currently closed, we are next open ${nextOpeningDay} at ${nextOpeningTime}.`;
      case errorCodes.CLOSED_OPENING_LATER_TODAY:
        return `We are opening soon at: ${openTime}.`;
      case errorCodes.TAKEAWAY_MISSING_PICKUP_TIME_OR_PICKUP_DAY:
        return 'Please select a takeaway day and time.';
      case errorCodes.VENUE_NOT_FOUND:
        return `Venue ${venueId || venuePath} not found.`;
      case errorCodes.ORDER_NOT_FOUND:
        return `Order not found.`;
      case errorCodes.MENU_NOT_FOUND:
        return `Menu not found.`;
      case errorCodes.TABLES_NOT_FOUND:
        return 'Sorry, there are no Tables available to select.';
      case errorCodes.PROBLEM_ENCODING_ITEM:
        return 'Sorry there seems to be an issue with this menu item.';
      default:
        return `Sorry, an error occurred, please try again. (${errorCode})`;
    }
  };
  const getRedirect = (errorCode) => {
    switch (errorCode) {
      case errorCodes.VENUE_NOT_FOUND:
        return {
          hide: true,
        };
      case errorCodes.ORDER_NOT_FOUND:
        return {
          url: `/menu/${venueId}`,
          text: 'Back to menu',
        };
      default:
        return {
          url: `/menu/${venueId}/${orderId}`,
          text: 'Back to menu',
        };
    }
  };
  const errorCode = error.message;
  return {
    message: getMessage(errorCode),
    redirect: getRedirect(errorCode),
    code: errorCode,
  };
};

export default getHumanReadableErrorMessage;
