import { createEnhancedAsynThunk, sliceHandler } from '../../utils';

const sliceArgs = {
  SLICE_NAME: 'venue',
  ENDPOINT: 'venues',
};

const fetchVenue = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchVenue];

const slice = sliceHandler({ sliceName, extraReducers });
const { actions, reducer } = slice;

export const { exampleReducer } = actions;
export { fetchVenue };
export default reducer;
