import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import MenuCategoryItem from '../MenuCategoryItem';
import getCategories from '../../../utils/getCategories';

import cySelectors from '../../../tests/cySelectors';

const MenuCategories = ({ handleModalToggle }) => {
  const { data: menuData } = useSelector((state) => state.menu);
  const { data: cartData, loading: cartLoading } = useSelector((state) => state.cart);

  const categories = useMemo(() => getCategories(menuData.menu), [menuData.menu]);

  // TODO: might need to refactor as is not pure fn cartData is being used
  // from the outer scope, still accessing passing cartData in each MenuCategoryItem
  // might be expensive performance wise.
  const getItemCount = useCallback(
    (itemId) => {
      const filteredItems = cartData?.filter(({ uniqItemId }) => {
        const item = JSON.parse(atob(uniqItemId));

        return item.itemId === itemId;
      });
      return filteredItems?.reduce(
        (acc, _current, index) => acc + filteredItems[index].quantity,
        0,
      );
    },
    [cartData],
  );

  return (
    <div className="menu__categories" data-cy={cySelectors.MENU_CATEGORIES}>
      {categories.map(({ categoryName, categoryId, categoryItems }) => (
        <div key={categoryId} className="menu__category">
          <h2 data-servedup-category-id={categoryId} className="menu__category__name">
            {categoryName}
          </h2>

          {!cartLoading &&
            categoryItems.map((item) => {
              const key = item.itemId;

              return (
                <MenuCategoryItem
                  key={key}
                  item={item}
                  handleModalToggle={() => handleModalToggle(item)}
                  itemCount={getItemCount(item.itemId)}
                />
              );
            })}
        </div>
      ))}
    </div>
  );
};

export default MenuCategories;
