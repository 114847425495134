import React, { useEffect, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import cySelectors from '../../../tests/cySelectors';

import formatCurrency from '../../../utils/formatCurrency';

const MenuModalSize = ({ itemPrices, itemOptions, handleSetSizeTotal }) => {
  const { register, setValue } = useFormContext();
  const [selected, setSelected] = useState({});
  const sizes = itemOptions.map((itemOption, i) => ({
    itemName: itemOption,
    itemPrice: itemPrices[i],
  }));

  const handleOnChange = (value) => {
    setSelected(value);
  };

  const preselectFirstOption = useCallback((options) => {
    const [firstOption] = options;

    setSelected(firstOption);
  }, []);

  const presetFirstOptionValue = useCallback(
    (options, values) => {
      const [firstOption] = options;
      const [firstValue] = values;

      handleSetSizeTotal(firstValue);
      setValue('itemOption', firstOption);
    },
    [handleSetSizeTotal, setValue],
  );

  const getCheckedItemStatus = (value, selectedValue) => value === selectedValue;

  useEffect(() => {
    preselectFirstOption(itemOptions);
    presetFirstOptionValue(itemOptions, itemPrices);
  }, [preselectFirstOption, presetFirstOptionValue, itemPrices, itemOptions]);

  return (
    <div className="menu__modal__size" data-cy={cySelectors.MODIFIERS_SIZES}>
      <fieldset>
        <legend>Select size</legend>
        {sizes.map(({ itemName, itemPrice }) => {
          // Declaration needed beforehand as we want to run register's methods + custom ones
          const sizeItem = register(`itemOption`);
          const formattedPrice = formatCurrency(itemPrice);

          return (
            <div
              key={itemName}
              className="menu__modal__size__choice"
              data-cy={cySelectors.MODIFIERS_SIZE}
            >
              <label htmlFor={itemName}>{itemName}</label>
              <span className="menu__modal__size__choice__price">{formattedPrice}</span>
              <input
                onChange={(event) => {
                  sizeItem.onChange(event);
                  handleSetSizeTotal(itemPrice);
                  handleOnChange(itemName);
                }}
                checked={getCheckedItemStatus(itemName, selected)}
                onBlur={sizeItem.onBlur}
                ref={sizeItem.ref}
                type="radio"
                id={itemName}
                name="itemOption"
                value={itemName}
              />
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default MenuModalSize;
