import React, { useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as almond } from '../../../assets/images/dietary/almond.svg';
import { ReactComponent as celery } from '../../../assets/images/dietary/celery.svg';
import { ReactComponent as crustaceans } from '../../../assets/images/dietary/crustaceans.svg';
import { ReactComponent as egg } from '../../../assets/images/dietary/egg.svg';
import { ReactComponent as fish } from '../../../assets/images/dietary/fish.svg';
import { ReactComponent as gluten } from '../../../assets/images/dietary/gluten.svg';
import { ReactComponent as lupin } from '../../../assets/images/dietary/lupin.svg';
import { ReactComponent as milk } from '../../../assets/images/dietary/milk.svg';
import { ReactComponent as mollusc } from '../../../assets/images/dietary/mollusc.svg';
import { ReactComponent as mustard } from '../../../assets/images/dietary/mustard.svg';
import { ReactComponent as peanuts } from '../../../assets/images/dietary/peanuts.svg';
import { ReactComponent as sesame } from '../../../assets/images/dietary/sesame.svg';
import { ReactComponent as soybean } from '../../../assets/images/dietary/soybean.svg';
import { ReactComponent as sulfide } from '../../../assets/images/dietary/sulfide.svg';

const icons = {
  almond,
  celery,
  crustaceans,
  egg,
  fish,
  gluten,
  lupin,
  milk,
  mollusc,
  mustard,
  peanuts,
  sesame,
  soybean,
  sulfide,
};

const MenuDietaryRequirement = ({ requirement }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { name, icon } = requirement;
  const Icon = icons[icon];
  const classes = clsx('menu__dietary-requirement', {
    'menu__dietary-requirement--expanded': isExpanded,
  });
  const handleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <button
      type="button"
      className={classes}
      onClick={handleExpansion}
      onKeyDown={handleExpansion}
      tabIndex={0}
    >
      <Icon />
      <span className="menu__dietary-requirement__label">{name}</span>
    </button>
  );
};

export default MenuDietaryRequirement;
