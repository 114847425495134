/* eslint-disable react/button-has-type */
import React from 'react';
import clsx from 'clsx';

import LoadingSpinner from '../LoadingSpinner';

const Button = ({
  fullWidth = false,
  white = false,
  type = 'button',
  loading,
  onClick,
  disabled = false,
  extraClasses,
  children,
  ...extraProps
}) => {
  const classes = clsx('btn', {
    'btn--fullwidth': fullWidth,
    'btn--white': white,
    'btn--blue': !white,
    'btn--loading': loading,
    [extraClasses]: extraClasses,
  });

  return (
    <button className={classes} type={type} onClick={onClick} disabled={disabled} {...extraProps}>
      {loading ? <LoadingSpinner /> : children}
    </button>
  );
};

export default Button;
