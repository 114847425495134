import React from 'react';
import { useSelector } from 'react-redux';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';

const ConfirmationDetails = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const {
    name,
    email,
    tableName,
    notes,
    subtotal,
    serviceSubtotal,
    total,
    paymentMethod,
    paymentService,
    pickupDay,
    payServiceCharge,
    pickupTime,
    systemCustomerNotes,
    totalDiscount,
  } = orderData || {};

  const formattedSubtotal = formatCurrency(subtotal);
  const formattedTotal = formatCurrency(total);
  const formattedServiceCharge = formatCurrency(serviceSubtotal);
  const formattedPickupTime = new Date(pickupDay).toLocaleString('en-GB', {
    weekday: 'long',
  });
  const pickupTimeText = `Your order will be delivered: ${formattedPickupTime} between: ${pickupTime}`;
  const paidViaText = `${paymentMethod}, ${paymentService}`;
  const hasDiscount = !!totalDiscount;
  const formattedDiscount = formatCurrency(totalDiscount);

  return (
    <div className="confirmation__details">
      <div className="confirmation__details__field" data-cy={cySelectors.CONFIRMATION_NAME}>
        Name: {name}
      </div>
      <div className="confirmation__details__field" data-cy={cySelectors.CONFIRMATION_EMAIL}>
        Email: {email}
      </div>
      {(payServiceCharge || hasDiscount) && (
        <div
          data-cy={cySelectors.CONFIRMATION_SUBTOTAL}
          className="confirmation__details__field--double"
        >
          <span>Subtotal:</span>
          <span>{formattedSubtotal}</span>
        </div>
      )}
      {payServiceCharge && (
        <div
          className="confirmation__details__field--double"
          data-cy={cySelectors.CONFIRMATION_SERVICE_CHARGE}
        >
          <span>Service charge:</span>
          <span>{formattedServiceCharge}</span>
        </div>
      )}
      {hasDiscount && (
        <div className="confirmation__details__field--double">
          <span>Offer:</span>
          <span>-{formattedDiscount}</span>
        </div>
      )}
      <div
        className="confirmation__details__field--double"
        data-cy={cySelectors.CONFIRMATION_TOTAL}
      >
        <span>Total:</span>
        <span>{formattedTotal}</span>
      </div>
      <div
        className="confirmation__details__field--double"
        data-cy={cySelectors.CONFIRMATION_TABLE}
      >
        <span>Table: {tableName}</span>
        {paymentMethod && paymentService && (
          <span data-cy={cySelectors.CONFIRMATION_PAID_VIA}>Paid via: {paidViaText}</span>
        )}
      </div>
      {pickupTime && (
        <div
          className="confirmation__details__field"
          data-cy={cySelectors.CONFIRMATION_TAKEAWAY_TIME}
        >
          {pickupTimeText}
        </div>
      )}
      {notes && (
        <div className="confirmation__details__field" data-cy={cySelectors.CONFIRMATION_NOTES}>
          Notes: {notes}
        </div>
      )}
      {systemCustomerNotes && (
        <div className="confirmation__details__field">{systemCustomerNotes}</div>
      )}
    </div>
  );
};

export default ConfirmationDetails;
