// https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971

import { useCallback, useState } from 'react';
import ServedUpError from '../utils/ServedUpError';

const useAsyncServedUpError = () => {
  const [_, setError] = useState();
  return useCallback(
    (params) => {
      setError(() => {
        throw new ServedUpError(params);
      });
    },
    [setError],
  );
};

export default useAsyncServedUpError;
