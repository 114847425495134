import React from 'react';

import MenuModalModifierRadio from '../MenuModalModifierRadio';
import MenuModalModifierCheckbox from '../MenuModalModifierCheckbox';

const MenuModalModifiers = ({
  modifiers,
  handleSetModifiersTotalRadio,
  handleSetModifiersTotalCheckbox,
}) => (
  <div className="menu__modal__modifiers">
    {modifiers.map((modifier) => {
      const isRadio = modifier.component === 'RADIO';
      const key = modifier.modifierId;

      if (isRadio) {
        return (
          <MenuModalModifierRadio
            key={key}
            modifier={modifier}
            modifierIndex={key}
            handleSetModifiersTotal={handleSetModifiersTotalRadio}
          />
        );
      }
      return (
        <MenuModalModifierCheckbox
          key={key}
          modifier={modifier}
          modifierIndex={key}
          handleSetModifiersTotal={handleSetModifiersTotalCheckbox}
        />
      );
    })}
  </div>
);

export default MenuModalModifiers;
