// TODO: refactor to make use of RKT query: https://redux-toolkit.js.org/rtk-query/overview
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'query-string';

import Logger from '../utils/Logger';

const createEnhancedAsynThunk = ({
  name,
  endpoint,
  externalEndpoint,
  resource,
  method,
  headers,
  payloadTransformer = (payload) => payload,
}) => {
  const { REACT_APP_API_URL } = process.env;
  return createAsyncThunk(`${name}/${method}`, async ({ params, payload, query }) => {
    const paramsPath = params ? `/${params}` : '';
    const resourcePath = resource ? `/${resource}` : '';
    const queries = query ? `?${qs.stringify(query)}` : '';
    const data = payload ? payloadTransformer(payload) : null;
    const url =
      externalEndpoint || `${REACT_APP_API_URL}/${endpoint}${paramsPath}${resourcePath}${queries}`;

    try {
      const response = await axios({
        method,
        url,
        data,
        headers,
      });
      return response.data;
    } catch (error) {
      Logger.error(error);
      const errorMessage = error.response.data.errorCode || error.response.message || error.message;

      throw new Error(errorMessage);
    }
  });
};

const sliceHandler = ({ sliceName, reducers = [], extraReducers = [], extraState }) => {
  const combinedExtraReducers = {};
  const updatedReducers = {
    ...reducers,
    appendToStateData: (state, action) => {
      const { payload } = action;

      state.data = {
        ...state.data,
        ...payload,
      };
    },
    replaceStateData: (state, action) => {
      const { payload } = action;

      state.data = payload;
    },
  };

  for (const extraReducer of extraReducers) {
    combinedExtraReducers[extraReducer.fulfilled] = (state, action) => {
      const { payload } = action;
      state.data = payload;
      state.loading = false;
      state.error = false;
    };

    combinedExtraReducers[extraReducer.pending] = (state, _action) => {
      state.loading = true;
    };

    combinedExtraReducers[extraReducer.rejected] = (state, action) => {
      const { error } = action;
      state.error = error;
      state.loading = false;
    };
  }

  return createSlice({
    name: sliceName,
    initialState: {
      data: null,
      loading: false,
      error: false,
      ...extraState,
    },
    reducers: updatedReducers,
    extraReducers: combinedExtraReducers,
  });
};

export { createEnhancedAsynThunk, sliceHandler };
