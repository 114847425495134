import React, { useState, useEffect, useCallback, useContext } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import Form from '../../../components/Form';
import AppContext from '../../../components/App/AppContext';

import MenuModalSize from '../MenuModalSize';
import MenuModalModifiers from '../MenuModalModifiers';
import MenuModalFooter from '../MenuModalFooter';
import MenuDietaryRequirements from '../MenuDietaryRequirements';

import { addToCart } from '../../../store/slices/order';

import { safeSum, safeMultiply } from '../../../utils/safeArithmetics';

import { ReactComponent as CrossIcon } from '../../../assets/images/cross.svg';
import useAsyncServedUpError from '../../../hooks/useAsyncServedUpError';

const MenuModal = ({ isModalOpen, handleCloseModal, currentItem }) => {
  const dispatch = useDispatch();
  const [itemQuantity, setItemQuantity] = useState(1);
  const [addToCartTotal, setAddToCartTotal] = useState(0);
  const [modifiersTotal, setModifiersTotal] = useState([]);
  const [sizeTotal, setSizeTotal] = useState(0);
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: orderData } = useSelector((state) => state.order);
  const { data: cartData } = useSelector((state) => state.cart);
  const {
    itemId,
    itemName,
    imgUrl,
    description,
    itemPrices,
    itemOptions,
    modifiers,
    dietaryRequirements,
  } = currentItem;
  const { isWebpSupported } = useContext(AppContext);

  const hasDietaryRequirements = dietaryRequirements?.length > 0;
  const webpUrl = imgUrl?.replace(/\.jpg$/, '.webp');
  const imgSrc = isWebpSupported ? webpUrl : imgUrl;
  const throwError = useAsyncServedUpError();

  // TODO: might need to refactor as is not pure fn venueId, orderId, patchOrder
  // are being used from the outer scope
  const onSubmit = async (fields) => {
    const { itemOption, modifiers: itemModifiers } = fields;
    const { venueId } = venueData;
    const { orderId } = orderData;
    const newItem = {
      itemId,
      itemOption,
      modifiers: itemModifiers,
    };
    const payload = {
      venueId,
      newItem,
      itemQuantity,
      cart: cartData,
    };

    try {
      await dispatch(addToCart({ params: orderId, payload })).unwrap();
    } catch (error) {
      throwError({ error, venueId, orderId });
    } finally {
      handleCloseModal();
    }
  };

  const calculateAddToCartTotal = useCallback(
    (modifiersAddToCartTotal, sizeAddToCartTotal, quantityAddToCart) => {
      const modifiersSum = modifiersAddToCartTotal.reduce(
        (acc, _curr, index, src) => acc + src[index].total,
        0,
      );

      return safeMultiply(safeSum(modifiersSum, sizeAddToCartTotal), quantityAddToCart);
    },
    [],
  );

  const increaseQuantity = () => {
    setItemQuantity(itemQuantity + 1);
  };

  const decreaseQuantity = () => {
    setItemQuantity((prevState) => (prevState <= 1 ? 1 : itemQuantity - 1));
  };

  const handleSetSizeTotal = useCallback((itemOptionPrice) => {
    setSizeTotal(itemOptionPrice);
  }, []);

  const handleSetModifiersTotalRadio = useCallback(
    (modifierId, modifierItems, selectedItemId) => {
      setModifiersTotal((prevState) => {
        const newState = [...prevState];
        const targetModifier = newState.find(
          (modifierItem) => modifierItem.modifierId === modifierId,
        );
        const { itemPrice } = modifierItems.find(
          (modifierItem) => modifierItem.itemId === selectedItemId,
        );

        if (targetModifier) {
          targetModifier.total = itemPrice;
        } else {
          newState.push({ modifierId, total: itemPrice });
        }

        return newState;
      });
    },
    [setModifiersTotal],
  );

  const handleSetModifiersTotalCheckbox = useCallback(
    (modifierId, modifierItems, selectedItemIds) => {
      setModifiersTotal((prevState) => {
        const newState = [...prevState];
        const targetModifier = newState.find(
          (modifierItem) => modifierItem.modifierId === modifierId,
        );
        const total = selectedItemIds.reduce((acc, _curr, index, src) => {
          const targetModifierItemId = src[index].itemId;
          const { itemPrice } = modifierItems.find(
            (modifierItem) => modifierItem.itemId === targetModifierItemId,
          );

          return acc + itemPrice;
        }, 0);

        if (targetModifier) {
          targetModifier.total = total;
        } else {
          newState.push({ modifierId, total });
        }

        return newState;
      });
    },
    [setModifiersTotal],
  );

  useEffect(() => {
    setAddToCartTotal(calculateAddToCartTotal(modifiersTotal, sizeTotal, itemQuantity));
  }, [calculateAddToCartTotal, itemQuantity, modifiersTotal, sizeTotal]);

  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={isModalOpen}
      className="menu__modal"
      contentLabel={itemName}
    >
      <header className="menu__modal__header">
        <div className="menu__modal__title">{itemName}</div>
        <button
          className="menu__modal__header__close-btn"
          type="button"
          aria-label="Close dialog"
          onClick={handleCloseModal}
          onKeyDown={handleCloseModal}
        >
          <CrossIcon />
        </button>
      </header>
      {imgUrl && (
        <div className="menu__modal__image">
          <img alt={itemName} src={imgSrc} />
        </div>
      )}
      <Form handleOnSubmit={onSubmit}>
        <div className="menu__modal__content">
          {description && <p>{description}</p>}
          {hasDietaryRequirements && (
            <MenuDietaryRequirements dietaryRequirements={dietaryRequirements} />
          )}
          {itemPrices && itemOptions && (
            <MenuModalSize
              itemPrices={itemPrices}
              itemOptions={itemOptions}
              handleSetSizeTotal={handleSetSizeTotal}
            />
          )}
          {modifiers && (
            <MenuModalModifiers
              modifiers={modifiers}
              handleSetModifiersTotalCheckbox={handleSetModifiersTotalCheckbox}
              handleSetModifiersTotalRadio={handleSetModifiersTotalRadio}
            />
          )}
        </div>
        <MenuModalFooter
          addToCartTotal={addToCartTotal}
          quantity={itemQuantity}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
        />
      </Form>
    </Modal>
  );
};

export default MenuModal;
