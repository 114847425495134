

import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import useQuery from '../../hooks/useQuery';

import VenueTakeAwayWarning from '../../components/VenueTakeAwayWarning';
import ServiceStatus from '../../components/ServiceStatus';
import Title from '../../components/Title';
import LoadingSpinner from '../../components/LoadingSpinner';
import FixedButton from '../../components/FixedButton';

import MenuNavigation from './MenuNavigation';
import MenuCategories from './MenuCategories';
import MenuModal from './MenuModal';

import formatCurrency from '../../utils/formatCurrency';
import cySelectors from '../../tests/cySelectors';

const MenuCampaignSlider = lazy(() => import('./MenuCampaignsSlider'));

const Menu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const history = useHistory();
  const queries = useQuery();
  const { orderId, venueId } = useParams();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: menuData } = useSelector((state) => state.menu);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { subtotal } = orderData || {};
  const { isOpen, isTakeawayEnabled } = venueData || {};
  const { campaigns, serviceStatus } = menuData || {};
  const { drinksDelay, foodDelay, isDrinksServiceOn, isFoodServiceOn } = serviceStatus || {};

  const formattedSubtotal = formatCurrency(subtotal);
  const isCartEmpty = !orderData?.cart?.length;
  const isTakeAwayBannerVisible = !isOpen && isTakeawayEnabled;
  const hasDrinkServiceAndIsDelayed = !!drinksDelay && isDrinksServiceOn;
  const hasFoodServiceAndIsDelayed = !!foodDelay && isFoodServiceOn;
  const hasServiceAndISDelayed = hasDrinkServiceAndIsDelayed || hasFoodServiceAndIsDelayed;
  const hasService = isDrinksServiceOn || isFoodServiceOn;
  const isServiceStatusVisible = isOpen && (hasServiceAndISDelayed || !hasService);
  const isBrandupEnabled = !!campaigns?.length;
  const itemIdQuery = queries.get('itemId');
  const modalOpenQuery = queries.get('modalOpen');
  const isModalQueryAvailable = itemIdQuery && modalOpenQuery;

  const handleScrollToCategory = (categoryId) => {
    const targetCategoryElement = document.querySelector(
      `[data-servedup-category-id="${categoryId}"]`,
    );

    if (targetCategoryElement) {
      targetCategoryElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleAppendItemQueryParam = useCallback(
    ({ itemId }) => {
      history.push({
        search: `?modalOpen=true&itemId=${itemId}`,
      });
    },
    [history],
  );

  const handleResetItemQueryParam = useCallback(() => {
    history.push({
      search: null,
    });
  }, [history]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    handleResetItemQueryParam();
  }, [handleResetItemQueryParam]);

  const handleModalToggle = useCallback(
    (item) => {
      setCurrentItem(item);
      setIsModalOpen(!isModalOpen);
      handleAppendItemQueryParam(item);
    },
    [handleAppendItemQueryParam, isModalOpen],
  );

  const getItemFromItemId = useCallback(
    (itemId) => {
      const items = menuData.menu.map(({ categoryItems }) => categoryItems).flat();
      return items.find((item) => item.itemId === itemId);
    },
    [menuData],
  );

  const handleOpenModalFromQuery = useCallback(() => {
    const item = getItemFromItemId(itemIdQuery);

    setCurrentItem(item);
    setIsModalOpen(true);
  }, [getItemFromItemId, itemIdQuery]);

  const handleModalToggleFromItemId = useCallback(
    ({ activeIndex }) => {
      const [itemId] = campaigns[activeIndex].productIds;
      const item = getItemFromItemId(itemId);

      setCurrentItem(item);
      setIsModalOpen(true);
    },
    [campaigns, getItemFromItemId],
  );

  const handleNavigateToReviewPage = () => {
    history.push(`/review/${venueId}/${orderId}`);
  };

  useEffect(() => {
    if (menuData && isModalQueryAvailable) {
      handleOpenModalFromQuery();
    }
  }, [isModalQueryAvailable, handleOpenModalFromQuery, menuData]);

  useEffect(() => {
    if (!isModalQueryAvailable) {
      handleCloseModal();
    }
  }, [handleCloseModal, isModalQueryAvailable]);

  return venueData && menuData ? (
    <div className="menu">
      {isTakeAwayBannerVisible && <VenueTakeAwayWarning />}
      {isServiceStatusVisible && <ServiceStatus />}
      <Title kicker="Welcome to" main={venueData.name} />
      <Suspense fallback={<LoadingSpinner />}>
        {isBrandupEnabled && (
          <MenuCampaignSlider
            campaigns={campaigns}
            handleModalToggleFromItemId={handleModalToggleFromItemId}
          />
        )}
      </Suspense>
      <MenuNavigation handleScrollToCategory={handleScrollToCategory} />
      <MenuCategories handleModalToggle={handleModalToggle} />
      {!isCartEmpty && (
        <FixedButton onClick={handleNavigateToReviewPage} data-cy={cySelectors.SUBMIT_BTN} loading={orderLoading}>
          <span>Go to checkout </span>
          <span>{formattedSubtotal}</span>
        </FixedButton>
      )}
      {isModalOpen && (
        <MenuModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          currentItem={currentItem}
        />
      )}
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Menu);
