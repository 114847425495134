import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

const Head = () => {
  const { data: venueData } = useSelector((state) => state.venue);
  const title = venueData?.name || 'ServedUp';

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"
        media="print"
        onLoad="this.media='all'"
      />
      <script defer src="https://cdn.checkout.com/js/framesv2.min.js" />
    </Helmet>
  );
};

export default Head;
