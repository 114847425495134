import { createEnhancedAsynThunk, sliceHandler } from '../../utils';

const sliceArgs = {
  SLICE_NAME: 'tables',
  ENDPOINT: 'venues',
  RESOURCE: 'tables',
};

const fetchTables = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: sliceArgs.RESOURCE,
  method: 'GET',
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchTables];

const slice = sliceHandler({ sliceName, extraReducers });
const { actions, reducer } = slice;

export const { exampleReducer } = actions;
export { fetchTables };
export default reducer;
