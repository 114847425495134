import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import CheckoutForm from './CheckoutForm';

import Title from '../../components/Title';
import BackButton from '../../components/BackButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import CartItems from '../../components/CartItems';
import Divider from '../../components/Divider';

const Checkout = ({ isQrPayment }) => {
  const { data: orderData } = useSelector((state) => state.order);
  const params = useParams();
  const showBackButton = !isQrPayment;

  const backButtonRoute = `/details/${params.venueId}/${params.orderId}`;

  return orderData ? (
    <div className="checkout">
      {showBackButton && <BackButton toRoute={backButtonRoute} />}
      <Title main="Checkout" />
      <div className="checkout__body">
        <p>
          By completing payment you abide by our{' '}
          <a href="https://servedup.theup.co/terms-conditions" target="_blank" rel="noreferrer">
            terms &amp; conditions
          </a>
          .
        </p>
      </div>
      {isQrPayment && (
        <>
          <Divider />
          <CartItems />
          <Divider />
        </>
      )}
      <CheckoutForm isQrPayment={isQrPayment} />
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Checkout);
