import pickBy from 'lodash.pickby';

import { createEnhancedAsynThunk, sliceHandler } from '../../utils';
import getAddToCartPayload from './getAddToCartPayload';
import getChangeCartItemQuantityPayload from './getChangeCartItemQuantityPayload';

const sliceArgs = {
  SLICE_NAME: 'order',
  ENDPOINT: 'orders',
};

const sliceName = sliceArgs.SLICE_NAME;

const createOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'POST',
});

const fetchOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
});

const addToCartPayloadTransformer = ({ venueId, cart, newItem, itemQuantity }) => {
  const updatedCart = getAddToCartPayload(cart, newItem, itemQuantity);

  return {
    venueId,
    cart: updatedCart,
  };
};

const addToCart = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: addToCartPayloadTransformer,
});

const changeCartItemQuantityPayloadTransformer = ({
  venueId,
  cart,
  uniqItemId,
  quantityChange,
  isDelete,
}) => {
  const updatedCart = getChangeCartItemQuantityPayload(cart, uniqItemId, quantityChange, isDelete);

  return {
    venueId,
    cart: updatedCart,
  };
};

const changeCartItemQuantity = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: changeCartItemQuantityPayloadTransformer,
});

const patchOrderPayloadTransformer = (payload) => {
  const { countryCode, phone, name, email, notes, venueId, tableName } = payload;
  const filteredPayload = { venueId, notes, name, email };
  const sanitizedCountryCode = countryCode ? countryCode.value : undefined;
  const sanitizedPhone = phone ? phone.replace(new RegExp(/^0/), '') : undefined;
  const sanitizedPhoneWithCountryCode = sanitizedPhone
    ? `${sanitizedCountryCode}${sanitizedPhone}`
    : undefined;
  const sanitizedTableName = tableName ? tableName.value : undefined;

  const sanitisedPayload = pickBy({
    ...filteredPayload,
    phone: sanitizedPhoneWithCountryCode,
    tableName: sanitizedTableName,
  });

  return sanitisedPayload;
};

const patchOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: patchOrderPayloadTransformer,
});

const addPromoCode = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
});

const extraReducers = [
  createOrder,
  fetchOrder,
  addToCart,
  changeCartItemQuantity,
  patchOrder,
  addPromoCode,
];

const slice = sliceHandler({ sliceName, extraReducers });
const { actions, reducer } = slice;

export const { exampleReducer } = actions;
export { createOrder, fetchOrder, addToCart, changeCartItemQuantity, patchOrder, addPromoCode };
export default reducer;
