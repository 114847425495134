import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import Title from '../../components/Title';
import LoadingSpinner from '../../components/LoadingSpinner';
import CartItems from '../../components/CartItems';
import Divider from '../../components/Divider';
import FixedButton from '../../components/FixedButton';

import ConfirmationDetails from './ConfirmationDetails';

import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';

const Confirmation = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const { venueId } = orderData || {};

  const handleNavigateToMenuPage = useCallback(() => {
    window.location.pathname = `/menu/${venueId}`;
  }, [venueId]);

  return orderData ? (
    <div className="confirmation">
      <div className="confirmation__message">
        <CheckIcon title="success" />
        <h2 className="confirmation__message__title">Payment Complete</h2>
        <p className="confirmation__message__text">
          You should receive an email when this order is confirmed by venue staff.
        </p>
      </div>
      <Title main="Your order" />
      <CartItems />
      <Divider />
      <ConfirmationDetails />
      <FixedButton onClick={handleNavigateToMenuPage}>Start new order</FixedButton>
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Confirmation);
