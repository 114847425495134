import { sliceHandler } from '../../utils';

const sliceArgs = {
  SLICE_NAME: 'cart',
};

const sliceName = sliceArgs.SLICE_NAME;

const reducers = {
  updateCart: (state, action) => {
    const { payload } = action;
    const updatedCart = payload.map(({ quantity, uniqItemId }) => ({
      quantity,
      uniqItemId,
    }));

    state.loading = false;
    state.data = updatedCart;
  },
  emptyCart: (state, _action) => {
    state.loading = false;
    state.data = [];
  },
};

const slice = sliceHandler({ sliceName, reducers });

const { actions, reducer } = slice;

export const { updateCart } = actions;
export default reducer;
