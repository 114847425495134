import React, { createContext } from 'react';

import detectWebpSupport from '../../../utils/detectWebpSupport';

const AppContext = createContext();
const appContextValues = {
  isWebpSupported: detectWebpSupport(),
};

const AppContextProvider = ({ children }) => {
  const propValues = { ...appContextValues };

  return <AppContext.Provider value={propValues}>{children}</AppContext.Provider>;
};

export { AppContext as default, appContextValues, AppContextProvider };
