import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import Title from '../../components/Title';
import LoadingSpinner from '../../components/LoadingSpinner';
import CartItems from '../../components/CartItems';

import ReviewForm from './ReviewForm';

import BackButton from '../../components/BackButton';

import { patchOrder } from '../../store/slices/order';
import useAsyncServedUpError from '../../hooks/useAsyncServedUpError';

const Review = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: orderData } = useSelector((state) => state.order);
  const params = useParams();
  const throwError = useAsyncServedUpError();

  const backButtonRoute = `/menu/${params.venueId}/${params.orderId}`;

  const handleNavigateToDetailsPage = useCallback(
    (venueId, orderId) => {
      history.push(`/details/${venueId}/${orderId}`);
    },
    [history],
  );

  const handlePatchNotes = useCallback(
    async (notes, venueId, orderId, setPatchNotesError) => {
      const payload = {
        venueId,
        notes,
      };

      try {
        await dispatch(patchOrder({ params: orderId, payload })).unwrap();
        setPatchNotesError(false);

        handleNavigateToDetailsPage(venueId, orderId);
      } catch (error) {
        setPatchNotesError(true);
        throwError({ error, venueId, orderId });
      }
    },
    [dispatch, handleNavigateToDetailsPage, throwError],
  );

  return orderData ? (
    <div className="review">
      <BackButton toRoute={backButtonRoute} />
      <Title main="Your order" />
      <CartItems editable />
      <ReviewForm handlePatchNotes={handlePatchNotes} />
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Review);
