/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import clsx from 'clsx';

import { FormContext } from '../Form';

const CustomInput = ({ children, selectProps, ...extraProps }) => (
  <components.Input {...extraProps} data-cy={selectProps['data-cy']} />
);

const FormFieldSelect = ({
  name,
  label,
  helpText,
  placeholder,
  prefillValue = null,
  defaultValue = null,
  options,
  showValueAsLabel,
  extraClasses,
  disabled,
  handleOnSelected = () => {},
  children,
  ...extraProps
}) => {
  const [selectValue, setSelectValue] = useState(null);
  const { validateOnLoad } = useContext(FormContext);
  const {
    control,
    getValues,
    setValue,
    formState: { dirtyFields, errors },
    trigger,
  } = useFormContext();
  const isFieldDirty = dirtyFields[name];
  const isValidateOnLoad = !isFieldDirty && getValues(name) && validateOnLoad;
  const classes = clsx(
    {
      [extraClasses]: extraClasses,
    },
    'form__field form__field__select',
  );
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #636264',
    }),
  };

  const handleSelectOnChange = (option) => {
    const { value } = option;
    const finalOption = showValueAsLabel ? { label: value, value } : option;

    handleOnSelected(option);
    setValue(name, finalOption);
    setSelectValue(finalOption);
  };

  useEffect(() => {
    if (prefillValue && !selectValue) {
      setSelectValue(prefillValue);
      setValue(name, prefillValue);
    }
  }, [name, prefillValue, selectValue, setValue]);

  useEffect(() => {
    if (!prefillValue && !selectValue) {
      const { value } = defaultValue || {};
      const finalDefaultValue = showValueAsLabel ? { label: value, value } : defaultValue;

      setSelectValue(finalDefaultValue);
      setValue(name, finalDefaultValue);
    }
  }, [defaultValue, name, prefillValue, selectValue, setValue, showValueAsLabel]);

  useEffect(() => {
    if (isValidateOnLoad) {
      trigger(name);
    }
  }, [isValidateOnLoad, name, trigger]);

  return (
    <div className={classes}>
      {label && (
        <label className="form__field__label" htmlFor={name}>
          {label}
        </label>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}
      <Controller
        aria-invalid={errors[name] ? 'true' : 'false'}
        name={name}
        isClearable
        control={control}
        render={({ field }) => (
          <Select
            components={{ Input: CustomInput }}
            {...field}
            {...extraProps}
            placeholder={placeholder}
            options={options}
            value={selectValue}
            onChange={handleSelectOnChange}
            styles={selectStyles}
            isDisabled={disabled}
            menuPlacement="auto"
          />
        )}
      />
      {errors[name] && <div className="form__field__error">{errors[name].message}</div>}
    </div>
  );
};

export default FormFieldSelect;
