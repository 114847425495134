import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FixedButton from '../../../components/FixedButton';
import Form from '../../../components/Form';
import FormField from '../../../components/FormField';
import FormFieldSelect from '../../../components/FormFieldSelect';

import formatCurrency from '../../../utils/formatCurrency';
import DetailsFormSchema from './DetailsFormSchema';
import DetailsFormPhoneField from './DetailsFormPhoneField';

import saveDetailsFormToLocalStorage from './saveDetailsFormToLocalStorage';
import countryCodes from './countryCodes';
import cySelectors from '../../../tests/cySelectors';

const DetailsForm = ({ handlePatchDetails }) => {
  const { orderId, venueId } = useParams();
  const [patchDetailsError, setPatchDetailsError] = useState(false);
  const [formInstance, setFormInstance] = useState();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { data: tablesData } = useSelector((state) => state.tables);
  const { subtotal } = orderData || {};
  const { isTakeawayEnabled, isOpen } = venueData || {};
  const isTakeAwayOnly = isTakeawayEnabled && !isOpen;

  const tablesOption = tablesData?.map((table) => ({
    value: table.tableName,
    label: table.tableName,
  }));

  const formattedSubtotal = formatCurrency(subtotal);
  const detailsFormStorage = window.localStorage.getItem('detailsForm');
  const { email, name, phone, tableName, countryCode } = JSON.parse(detailsFormStorage) || {};
  const isValidTableName = tablesOption?.find(
    (table) => table.value.toLowerCase() === tableName?.value.toLowerCase(),
  );
  const prefillTableName = isTakeAwayOnly
    ? { value: 'Takeaway', label: 'Takeaway' }
    : isValidTableName || null;
  const defaultCountryCodeOption = { value: '+44', label: '+44' };
  const isValid = formInstance?.isValid;

  const handleOnSubmit = (values) => {
    const { setError } = formInstance;

    saveDetailsFormToLocalStorage(values);
    handlePatchDetails(values, venueId, orderId, setPatchDetailsError, setError);
  };

  return (
    <div className="details__form">
      <Form
        handleOnSubmit={handleOnSubmit}
        validationSchema={DetailsFormSchema}
        setFormInstance={setFormInstance}
        validateOnLoad
      >
        <FormFieldSelect
          name="tableName"
          options={tablesOption}
          prefillValue={prefillTableName}
          placeholder="Your table number"
          helpText="So we know where to bring your order"
          label="Your table number"
          disabled={isTakeAwayOnly}
          data-cy={cySelectors.DETAILS_FORM_TABLE}
        />
        <FormField
          name="name"
          type="text"
          placeholder="Your name"
          prefillValue={name}
          label="Your name"
          helpText="We'll use this to find you at your table"
          autocomplete="name"
          data-cy={cySelectors.DETAILS_FORM_NAME}
        />
        <FormField
          name="email"
          type="text"
          prefillValue={email}
          placeholder="Your email"
          label="Your email"
          helpText="We'll use this to send you order updates"
          autocomplete="email"
          data-cy={cySelectors.DETAILS_FORM_EMAIL}
        />
        <DetailsFormPhoneField
          phone={phone}
          countryCode={countryCode}
          countryCodes={countryCodes}
          defaultCountryCodeOption={defaultCountryCodeOption}
        />

        <FixedButton type="submit" disabled={!isValid} loading={orderLoading} data-cy={cySelectors.SUBMIT_BTN}>
          <span>{patchDetailsError ? 'Please try again' : 'Continue'}</span>
          <span>{formattedSubtotal}</span>
        </FixedButton>
      </Form>
    </div>
  );
};

export default DetailsForm;
