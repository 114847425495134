import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Form from '../../../components/Form';
import FormFieldSelect from '../../../components/FormFieldSelect';
import Divider from '../../../components/Divider';

import getHumanReadableErrorMessage from '../../../utils/getHumanReadableErrorMessage';
import errorCodes from '../../../utils/errorCodes';

import CheckoutContext from '../CheckoutContext';
import PaymentSystems from '../PaymentSystems';
import CheckoutFormTakeAwaySchema from './CheckoutFormTakeAwaySchema';
import PromoCode from '../PromoCode';
import CheckoutFormFooter from '../CheckoutFormFooter';

import { safeSum } from '../../../utils/safeArithmetics';

import cySelectors from '../../../tests/cySelectors';

const userPaidStatuses = ['USER_PAID', 'USER_PAID_TAKEAWAY', 'authorized', 'succeeded'];
const checkIfPaid = (status) =>
  userPaidStatuses.some((userPaidStatus) => userPaidStatus === status);

const CheckoutForm = ({ isQrPayment }) => {
  const [pickupTimesOptionsTimeSlots, setPickupTimesOptionsTimeSlots] = useState(null);
  const [isServiceChargeEnabled, setIsServiceChargeEnabled] = useState(true);
  const [paymentError, setPaymentError] = useState(null);
  const [isUserPaying, setIsUserPaying] = useState(false);
  const history = useHistory();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: pickupTimesData } = useSelector((state) => state.pickupTimes);
  const { data: orderData } = useSelector((state) => state.order);
  const { data: checkoutData } = useSelector((state) => state.checkout);
  const { tableName, total, serviceSubtotal, venueId, orderId, promoCode } = orderData || {};
  const { status: checkoutStatus, payment } = checkoutData || {};
  const { status: orderStatus } = orderData || {};
  const { paymentSystems, serviceCharge } = venueData || {};

  const isDeclined = checkoutStatus === 'declined';
  const isUserPaid = checkIfPaid(checkoutStatus) || checkIfPaid(orderStatus);
  const finalTotal = isServiceChargeEnabled ? safeSum(total, serviceSubtotal) : total;
  const isTakeAwayTable = tableName.toLowerCase().includes('takeaway');
  const ValidationSchema = isTakeAwayTable ? CheckoutFormTakeAwaySchema : false;
  const pickupTimesOptionsDays = pickupTimesData?.pickupTimes.map(({ dayLabel, day }) => ({
    value: day,
    label: dayLabel,
  }));
  const isServiceChargeDisabled = serviceCharge === 'DISABLED';

  const setDefaultPickupTimes = useCallback(() => {
    const { pickupTimes } = pickupTimesData || {};
    const defaultPickupTimes = pickupTimes[0].pickupTimes.map((time) => ({
      value: time,
      label: time,
    }));
    setPickupTimesOptionsTimeSlots(defaultPickupTimes);
  }, [pickupTimesData, setPickupTimesOptionsTimeSlots]);

  const handleOnPickupTimeDaySelected = (selected) => {
    const targetPickupDay = pickupTimesData?.pickupTimes.find(({ day }) => day === selected.value);
    const targetPickupDayTimeSlots = targetPickupDay.pickupTimes;
    const targetPickupDayTimeSlotsOptions = targetPickupDayTimeSlots.map((timeSlot) => ({
      value: timeSlot,
      label: timeSlot,
    }));

    setPickupTimesOptionsTimeSlots(targetPickupDayTimeSlotsOptions);
  };

  const handlePaymentError = useCallback(
    ({ error, enforcedErrorCode, message }) => {
      const errorData = payment?.response_summary;
      const errorCode = enforcedErrorCode || error?.message;
      const { message: humanReadableError } =
        { message } || getHumanReadableErrorMessage(errorCode, errorData);

      setPaymentError(humanReadableError);
    },
    [payment?.response_summary],
  );

  const handleDeclinedPayment = useCallback(() => {
    if (isDeclined) {
      handlePaymentError({ enforcedErrorCode: errorCodes.PAYMENT_DECLINED });
    } else {
      setPaymentError(null);
    }
  }, [handlePaymentError, isDeclined]);

  const handleUserPaid = useCallback(() => {
    history.push(`/confirmation/${venueId}/${orderId}`);
  }, [history, orderId, venueId]);

  const handleDisableServiceCharge = useCallback(() => {
    setIsServiceChargeEnabled(false);
  }, [setIsServiceChargeEnabled]);

  useEffect(() => {
    if (isServiceChargeDisabled) {
      handleDisableServiceCharge();
    }
  }, [isServiceChargeDisabled, handleDisableServiceCharge]);

  useEffect(() => {
    if (isUserPaid) {
      handleUserPaid();
    }
  }, [handleUserPaid, isUserPaid]);

  useEffect(() => {
    handleDeclinedPayment();
  }, [handleDeclinedPayment]);

  useEffect(() => {
    if (isTakeAwayTable && pickupTimesData) {
      setDefaultPickupTimes();
    }
  }, [setDefaultPickupTimes, isTakeAwayTable, pickupTimesData]);

  useEffect(() => {
    if (paymentError) {
      setIsUserPaying(false);
    }
  }, [paymentError]);

  return (
    <CheckoutContext.Provider
      value={{
        finalTotal,
        isDeclined,
        isUserPaid,
        isServiceChargeEnabled,
        setIsServiceChargeEnabled,
        paymentError,
        setPaymentError,
        handlePaymentError,
        isQrPayment,
        isUserPaying,
        setIsUserPaying,
      }}
    >
      <div className="checkout__form">
        <Form validationSchema={ValidationSchema} validateOnLoad>
          {isTakeAwayTable && (
            <>
              <FormFieldSelect
                name="pickupDay"
                options={pickupTimesOptionsDays}
                defaultValue={pickupTimesOptionsDays && pickupTimesOptionsDays[0]}
                label="Takeaway time"
                handleOnSelected={handleOnPickupTimeDaySelected}
                extraClasses="checkout__form__takeaway-day"
                data-cy={cySelectors.CHECKOUT_TAKEAWAY_DAY}
              />
              <FormFieldSelect
                name="pickupTime"
                options={pickupTimesOptionsTimeSlots}
                defaultValue={pickupTimesOptionsTimeSlots && pickupTimesOptionsTimeSlots[0]}
                data-cy={cySelectors.CHECKOUT_TAKEAWAY_TIME}
              />
            </>
          )}
          {!isUserPaid && <PaymentSystems systems={paymentSystems} />}
          {paymentError && <div className="checkout__form__error">{paymentError}</div>}
          {!promoCode && <PromoCode />}
          <Divider />
          <CheckoutFormFooter />
        </Form>
      </div>
    </CheckoutContext.Provider>
  );
};

export default CheckoutForm;
