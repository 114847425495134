import React from 'react';
import { useSelector } from 'react-redux';

import ServiceCharge from '../ServiceCharge';
import Discounts from '../Discounts';

import formatCurrency from '../../../utils/formatCurrency';

const CheckoutFormFooter = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const { subtotal, discounts, serviceCharge } = orderData || {};

  const isServiceChargeDisabled = serviceCharge === 'DISABLED';
  const promoCodeDiscounts = discounts.filter(({ promoCode }) => promoCode);
  const hasPromoCodeDiscounts = !!promoCodeDiscounts.length;
  const formattedSubtotal = formatCurrency(subtotal);

  return (
    <div className="checkout__form__footer">
      <div className="checkout__form__footer__item">
        <span>Subtotal:</span>
        <span>{formattedSubtotal}</span>
      </div>
      {hasPromoCodeDiscounts && <Discounts discounts={promoCodeDiscounts} />}
      {!isServiceChargeDisabled && <ServiceCharge />}
    </div>
  );
};

export default CheckoutFormFooter;
