import React from 'react';

import MenuCategoryItemLazyLoadImage from '../MenuCategoryItemLazyLoadImage';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';

const MenuCategoryItem = ({ item, handleModalToggle, itemCount }) => {
  const { itemName, description, itemPrices, thumbUrl } = item;
  const formattedPrice = formatCurrency(itemPrices[0]);

  return (
    <div
      role="button"
      tabIndex={0}
      className="menu__category-item"
      onClick={handleModalToggle}
      onKeyDown={handleModalToggle}
      data-cy={cySelectors.MENU_CATEGORY_ITEM}
    >
      <div className="menu__category-item__info">
        <h3 className="menu__category-item__name">
          {itemName}
          {!!itemCount && <span className="menu__category-item__count"> x {itemCount}</span>}
        </h3>
        {description && <p className="menu__category-item__description">{description}</p>}
        <div className="menu__category-item__price">{formattedPrice}</div>
      </div>
      {thumbUrl && <MenuCategoryItemLazyLoadImage alt={itemName} thumbUrl={thumbUrl} />}
    </div>
  );
};

export default MenuCategoryItem;
