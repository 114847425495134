const saveTableNameToLocalStorage = (tableName) => {
  const detailsFormSaved = JSON.parse(window.localStorage.getItem('detailsForm'));
  const updatedDetailsForm = { ...detailsFormSaved };

  updatedDetailsForm.tableName = { value: tableName, label: tableName };

  const stringifiedUpdatedDetailsForm = JSON.stringify(updatedDetailsForm);

  window.localStorage.setItem('detailsForm', stringifiedUpdatedDetailsForm);
};

export default saveTableNameToLocalStorage;
