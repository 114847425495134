import React from 'react';
import ReactDOM from 'react-dom';

import './styles/main.output.css';

import * as Sentry from '@sentry/react';
import App from './components/App';
import { AppContextProvider } from './components/App/AppContext';

import reportWebVitals from './reportWebVitals';
import { isDev, isLocal } from './utils';
import initSentry from './utils/sentry';
import ErrorFallback from './pages/ErrorFallback';

initSentry(isDev, isLocal);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorFallback error={error} componentStack={componentStack} resetError={resetError} />
      )}
    >
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
