const errorCodes = {
  CAN_T_CHECKOUT_IN_THIS_STATUS: 'CAN_T_CHECKOUT_IN_THIS_STATUS',
  TAKEAWAY_MISSING_PICKUP_TIME_OR_PICKUP_DAY: 'TAKEAWAY_MISSING_PICKUP_TIME_OR_PICKUP_DAY',
  INVALID_PAYMENT: 'INVALID_PAYMENT',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_DECLINED: 'PAYMENT_DECLINED',
  CARD_TOKENIZED_FAILURE: 'CARD_TOKENIZED_FAILURE',
  INVALID_PHONE: 'INVALID_PHONE',
  CLOSED: 'CLOSED',
  CLOSED_OPENING_LATER_TODAY: 'INVALID_PHONE',
  VENUE_NOT_FOUND: 'VENUE_NOT_FOUND',
  ORDER_NOT_FOUND: 'ORDER_NOT_FOUND',
  MENU_NOT_FOUND: 'MENU_NOT_FOUND',
  TABLES_NOT_FOUND: 'TABLES_NOT_FOUND',
  PROBLEM_ENCODING_ITEM: 'PROBLEM_ENCODING_ITEM',
};

export default errorCodes;
