import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FixedButton from '../../../components/FixedButton';
import Form from '../../../components/Form';
import FormField from '../../../components/FormField';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';

const ReviewForm = ({ handlePatchNotes }) => {
  const { orderId, venueId } = useParams();
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const [patchNotesError, setPatchNotesError] = useState(false);
  const { subtotal, notes } = orderData || {};

  const formattedSubtotal = formatCurrency(subtotal);

  const handleOnSubmit = (values) => {
    const { newNotes } = values;

    handlePatchNotes(newNotes, venueId, orderId, setPatchNotesError);
  };

  return (
    <div className="review__form">
      <Form handleOnSubmit={handleOnSubmit}>
        <FormField
          name="newNotes"
          placeholder="Notes"
          prefillValue={notes}
          helpText="Let us know if you have any additional requests"
          label="Notes"
          data-cy={cySelectors.REVIEW_FORM_NEW_NOTES}
        />
        <FixedButton type="submit" loading={orderLoading} data-cy={cySelectors.SUBMIT_BTN}>
          <span>{patchNotesError ? 'Please try again' : 'Continue'}</span>
          <span>{formattedSubtotal}</span>
        </FixedButton>
      </Form>
    </div>
  );
};

export default ReviewForm;
