/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import pickBy from 'lodash.pickby';

import useYupValidationResolver from '../../hooks/useYupValdationResolver';

// TODO: discuss if worth to integrate https://react-hook-form.com/advanced-usage#FormProviderPerformance
// on <FormProvider /> to avoid re-renders.
const FormContext = createContext();

const Form = ({
  handleOnSubmit = () => {},
  validationSchema,
  setFormInstance,
  children,
  validateOnLoad,
}) => {
  // One of the few occations when rule-of-hooks conditional executing can be ignored.
  const resolver = validationSchema ? useYupValidationResolver(validationSchema) : null;
  const formOptions = pickBy({
    mode: 'onBlur',
    resolver,
  });
  const formMethods = useForm(formOptions);
  const {
    setError,
    formState: { isValid },
  } = formMethods;

  const setFormInstanceCallback = useCallback(() => {
    setFormInstance({ setError, isValid });
  }, [isValid, setError, setFormInstance]);

  useEffect(() => {
    if (setFormInstance) {
      setFormInstanceCallback();
    }
  }, [setFormInstance, setFormInstanceCallback]);

  return (
    <FormContext.Provider value={{ validateOnLoad }}>
      <FormProvider {...formMethods}>
        <form className="form" onSubmit={formMethods.handleSubmit(handleOnSubmit)}>
          {children}
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
};

export { Form as default, FormContext };
